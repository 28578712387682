$(document).on 'ready turbolinks:load', ->
  window.registrationPage = new RegistrationPage()
  window.registrationPage.init()

class RegistrationPage extends window.EventedClass
  constructor: ->
    super()
    @registrationWizardObject = $('#registration_form_wizard')
    @registrationFormWizard = new RegistrationFormWizard( @registrationWizardObject )
    @registrationFormObject = $('#registration_form')
    @registrationForm = new RegistrationForm( @registrationFormObject )

  init: ->
    if @registrationFormObject.length
      @registrationForm.bind()
    if @registrationWizardObject.length
      @registrationFormWizard.bind()

class RegistrationForm
  constructor: (@root) ->
    @loader = @root.find('#section-loader')
    @countrySelect = @root.find('#user_address_country')
    @phoneCodeSelect = @root.find('#user_address_phone_code')
    @stateSelect = @root.find('#user_address_state')
    @termsOfUseContainer = @root.find('#terms_of_use')
    @finishButton = @root.find('[type=submit]')
    @genderSelect = @root.find('#user_gender')

  bind: ->
    @countrySelect.on 'change', (e) =>
      $.ajax({
        url: @countrySelect.data().url,
        type: 'POST',
        data: { user: { address_country: e.target.value } },
        dataType: 'json',
        beforeSend: =>
          @clearAlerts()
          $('#cw-registration-second-step-next-btn').attr 'disabled', false
          if $('.mobileAppInstalledCheckBox').is(':checked')
            @finishButton.removeAttr('disabled').removeClass('disabled')
          @loader.show()
        success: (data) =>
          @phoneCodeSelect.find('option').removeAttr('selected')
          @phoneCodeSelect.find('option[data-icon$=" ' + e.target.value + '"]').attr('selected', 'selected')
          @phoneCodeSelect.selectpicker('refresh')
          @phoneCodeSelect.selectpicker('destroy')
          @phoneCodeSelect.selectpicker()

          @termsOfUseContainer.empty().append(data.terms_of_use_html)
          if !data.registration_enabled
            $('#cw-registration-second-step-next-btn').attr 'disabled', true
            @root.prepend @bsAlert(data.errors, 'danger')

          if data.state_is_required
            @stateSelect.attr('required', 'required')
            @stateSelect.parents('.form-group').find('label').addClass('required')
            @stateSelect.parents('.form-group').removeClass('d-none')
          else
            @stateSelect.removeAttr('required')
            @stateSelect.parents('.form-group').find('label').removeClass('required')
            @stateSelect.parents('.form-group').addClass('d-none')

        complete: =>
          @loader.hide()
      })

    @root.on 'ajax:beforeSend', (e) =>
      @clearAlerts()
      @loader.show()

    @root.on 'ajax:complete', (e) =>
      @loader.hide()

    @root.on 'ajax:success', (e) =>
      detail = e.detail
      data = detail[0]; status = detail[1]; xhr = detail[2]
      resp = JSON.parse(xhr.responseText)
      if resp['social_reg']
        @root.hide()
        $('#welcome-msg').show()
      else
        redirection_url = JSON.parse(xhr.responseText)['redirection_url']
        window.location.href = redirection_url

    @root.on 'ajax:error', (e) =>
      detail = e.detail
      data = detail[0]; status = detail[1]; xhr = detail[2]

      errors = JSON.parse(xhr.responseText)['errors']
      if errors.hasOwnProperty('base')
        @root.prepend @bsAlert(errors.base, 'danger')

      @renderFormErrors('user', errors)

  renderFormErrors: (model_name, errors, root = null) ->
    form = @root
    that = this
    errorsTabId = undefined

    @clearFormErrors(root || @root)

    $.each(errors, (field, messages) ->
      input = form.find(':input').filter(->
        name = $(this).attr('name')
        if name
          mapped_fields = $.map(field.split('.'), (el) ->
            '\\[' + el + '\\]'
          ).join ''
          name.match(new RegExp(model_name + mapped_fields))
      )

      if errorsTabId == undefined
        errorsTabId = input.closest('.tab-pane').attr('id')

      input.addClass('is-invalid')
      if input.closest('div.form-group .dropdown.bootstrap-select').length > 0
        input.closest('div.form-group .dropdown.bootstrap-select').append(that.messageBlock(messages))
      else if input.closest('div.form-group .styled-checkbox').length > 0
        input.closest('div.form-group').append(that.messageBlock(messages)).find('.styled-checkbox').addClass('is-invalid')
      else
        input.closest('div.form-group').append(that.messageBlock(messages))
    )
    if errorsTabId != undefined
      #@loader.hide()
      targetTabToShow = '.registration-step-wizard .nav-item a[href="#'+errorsTabId+'"]'
      triggerEl = document.querySelector(targetTabToShow)
      if triggerEl
        bootstrap.Tab.getOrCreateInstance(triggerEl).show()
      else
        console.error("Could not find the targeted step with errors")


  clearFormErrors: (root) ->
    root.find('.form-group :input, .form-group .styled-checkbox').removeClass('is-invalid')
    root.find('div.invalid-feedback').remove()

  clearAlerts: ->
    $('.alert').remove()

  bsAlert: (msg, type) ->
    """
    <div class="alert alert-#{type} alert-dismissible fade show" role="alert">
      #{msg}
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div>
    """

  messageBlock: (messages) ->
    """
    <div class="invalid-feedback">
      #{ $.map(messages, (message) -> message.charAt(0).toUpperCase() + message.slice(1)).join('<br />') }
    </div>
    """

class RegistrationFormWizard
  constructor: (@root) ->

  bind: ->
    if @root.find('.nav').length
      @root.smartWizard({
        selected: 0,
        theme: 'default',
        autoAdjustHeight: true,
        backButtonSupport: true,
        toolbar: {
          showNextButton: false,
          showPreviousButton: false,
        },
        anchor: {
          anchorClickable: true,
          enableAllAnchors: false,
        },
        enableUrlHash: false,
        keyboard: {
          keyNavigation: false,
        },
        transition: {
          animation: 'fade', # Animation effect on navigation, none|fade|slideHorizontal|slideVertical|slideSwing|css(Animation CSS class also need to specify)
        },
      })
      @handleTopNavigation()
    else
      console.error("Element '.nav' not found in wizard")

    @root.on('leaveStep', (e, anchorObject, currentStepIndex, nextStepIndex, stepDirection) =>
      if stepDirection == 'forward'
        @onNext(e, anchorObject, currentStepIndex)
      else if stepDirection == 'backward'
        @onPrevious(e, anchorObject, currentStepIndex)
    )
    $('.mobileAppInstalledCheckBox').on 'change', () =>
      if $('.mobileAppInstalledCheckBox').is(':checked')
        $('#submitUserRegistrationButton').attr 'disabled', false
      else
        $('#submitUserRegistrationButton').attr 'disabled', true

  handleTopNavigation: ->
    $('.registration-step-wizard .nav-link').on 'click', (e) =>
      e.preventDefault()

  onPrevious: (tab, navigation, step_nbr) ->
    form = navigation.prevObject.closest('form')
    if form.find('.tab-pane[style*="display: block"]').attr('id') == "tab2" || step_nbr == 1
      navigation.prevObject.closest('form').removeClass('preValidated')
      window.registrationPage.registrationForm.clearFormErrors($('#tab1'))
    else
      navigation.prevObject.closest('form').removeClass('secondStepValidated')
      window.registrationPage.registrationForm.clearFormErrors($('#tab2'))

  onNext: (tab, navigation, step_nbr) ->
    form = navigation.prevObject.closest('form')
    registrationForm = window.registrationPage.registrationForm
    isValid = true

    formError = (e) ->
      jQuery(e).addClass('shake animated').one 'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', ->
        jQuery(this).removeClass 'shake animated'
        return
      return

    if form.hasClass('secondStepValidated')
      form.find('.tab-pane[style*="display: block"] :input').each ->
        if !this.checkValidity()
          $('.tab-pane[style*="display: block"]').addClass('was-validated')
          formError($('.tab-pane[style*="display: block"]'))
          isValid = false
          return false
    else if form.hasClass('preValidated')
      form.find('.tab-pane[style*="display: block"] :input').each ->
        if !this.checkValidity()
          $('.tab-pane[style*="display: block"]').addClass('was-validated')
          formError($('.tab-pane[style*="display: block"]'))
          form.removeClass('secondStepValidated')
          isValid = false
          return false
      form.addClass('secondStepValidated') if isValid
    else
      $.ajax({
        url: $('#tab1').attr('data-url'),
        type: 'POST',
        data: form.serialize(),
        async: false,
        dataType: 'json',
        beforeSend: =>
          registrationForm.clearFormErrors($('#tab1'))
          registrationForm.loader.show()
        success: (data) =>
          isValid = data.valid
          return form.addClass('preValidated') if isValid
          $('.tab-pane[style*="display: block"]').addClass('was-validated')
          formError($('.tab-pane[style*="display: block"]'))
          registrationForm.renderFormErrors('user', data.errors, $('#tab1'))
        error: =>
          registrationForm.loader.hide()
        complete: =>
          registrationForm.loader.hide()
      })

    isValid
