$(document).on('load turbolinks:load', function() {

    'use strict';

    // ------------------------------------------------------- //
    // Bootstrap Validation
    // ------------------------------------------------------ //
	(function () {
		'use strict';
		window.addEventListener('load', function () {
			// Fetch all the forms we want to apply custom Bootstrap validation styles to
			var forms = document.getElementsByClassName('needs-validation');
			// Loop over them and prevent submission
			var validation = Array.prototype.filter.call(forms, function (form) {
				form.addEventListener('submit', function (event) {
					if (form.checkValidity() === false) {
						event.preventDefault();
						event.stopPropagation();
						formError(form);
					}
					form.classList.add('was-validated');
				}, false);
			});
		}, false);
	})();
	function formError(e) {
		jQuery(e).addClass('shake animated').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
			jQuery(this).removeClass('shake animated');
		});
	}
});
