$(document).on 'ready turbolinks:load', ->
  window.assestmentResultPage = new AssestmentResultPage()
  window.assestmentResultPage.init()

class AssestmentResultPage extends window.EventedClass
  constructor: ->
    super()
    @chartForTrainingObject = $('#chart_for_training')
    @chartForTraining = new PlotChartForTraining( @chartForTrainingObject )

  init: ->
    if @chartForTrainingObject.length
      @chartForTraining.bind()

class PlotChartForTraining
  constructor: (@root) ->
    @scores = @root.data('scores')
    @personal_score = @root.data('personal_score')

  bind: ->
    $.plot(@root, @data(), @options());

  data: ->
    [
      {
        data: @scores,
        label: I18n.t('workplace.agreements.result.distribution_of_all_results'),
        bars: {
          show: true,
          align: "center",
          barWidth: 10
        }
      }, {
        data: [[parseInt(@personal_score, 10), 99]],
        label: I18n.t('workplace.agreements.result.your_result') + ': ' + @personal_score,
        bars: {
          show: true,
          barWidth: 1
        }
      }
    ]

  options: ->
    {
      xaxis: {
        ticks: [
          [10, ""],
          [20, "20%"],
          [30, ""],
          [40, "40%"],
          [50, ""],
          [60, "60%"],
          [70, ""],
          [80, "80%"],
          [90, ""],
          [100, "100% " + I18n.t('workplace.agreements.result.x_axis')]
        ]
      },
      yaxis: {
        ticks: [
          [0, "0"],
          [20, "20%"],
          [40, "40%"],
          [60, "60%"],
          [80, "80%"],
          [100, I18n.t('workplace.agreements.result.y_axis') + " 100% "]
        ],
        min: 0,
        max: 100
      },
      legend: {}
    }

