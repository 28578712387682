$(document).on 'ready turbolinks:load', ->
  if $('form#edit_job').length > 0
    $('#edit_job').on 'ajax:error', ->
      $('#message_block').html(
        '<div class="alert alert-danger fade alert-dismissible show"><ul class="ul-disc ps-3 mb-0"><li>Internal server error</li></ul></div>'
      )

    $("#edit_job").on 'ajax:complete', (event)->
      xhr = event.detail[0]
      if ((xhr.getResponseHeader('Content-Type') || '').substring(0, 9) == 'text/html')
        referrer = xhr.responseURL
        snapshot = Turbolinks.Snapshot.wrap(xhr.response)
        Turbolinks.controller.cache.put(referrer, snapshot)
        Turbolinks.visit(referrer, { action: 'restore' })
        window.dispatchEvent(new Event('load'))
        document.dispatchEvent(new Event('DOMContentLoaded'))
        Turbolinks.dispatch('turbolinks:load')

    $(".other-text-field-tag").each ->
      otherTextElement = $(this)
      otherTextElement.closest('.df-element-main-content-wrapper').find('input[type=radio],input[type=checkbox],select').each ->
        $(this).change ->
          if (if $(this).prop('type') == 'checkbox' then $(this).parent().parent().find('input[value=_other_]').is(':checked') else $(this).val() == '_other_')
            otherTextElement.show()
            otherTextElement.focus()
          else
            otherTextElement.hide()
            otherTextElement.val('')

    fetchBackgroundImage = ->
      page_background = document.getElementById('page_background')
      jobPromoDiv = document.getElementById('job-background-div')
      default_img_url = jobPromoDiv.getAttribute('data-default-url')


      if page_background
        image_url = page_background.getAttribute('data-background-id')
        if image_url
          console.log 'Page background element:', page_background
          console.log 'Job promo div:', jobPromoDiv
          console.log 'Default image URL:', default_img_url
          console.log 'Background image URL:', image_url
          if jobPromoDiv
            jobPromoDiv.style.backgroundPosition = 'center'
            jobPromoDiv.style.backgroundImage = "linear-gradient(0deg, rgba(var(--dashboard-main-background-rgb), 1) 0%, rgba(var(--dashboard-main-background-rgb), .75) 15%, rgba(var(--dashboard-main-background-rgb), 0) 40%), url('"+image_url+"')"
            jobPromoDiv.style.height = '95vh'
            jobPromoDiv.style.position = 'fixed'
            jobPromoDiv.classList.add 'has-page-background'
        else
          jobPromoDiv.classList.remove 'has-page-background'
          if default_img_url
            jobPromoDiv.style.backgroundImage = "linear-gradient(0deg, rgba(var(--dashboard-main-background-rgb), 1) 0%, rgba(var(--dashboard-main-background-rgb), .75) 15%, rgba(var(--dashboard-main-background-rgb), 0) 40%), url('"+default_img_url+"')"
          else
            jobPromoDiv.style.backgroundImage = ''

    fetchBackgroundImage()

