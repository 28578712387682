/* some improvements for a 2nd round
// expiration 1 month = 2419200000 in milliseconds
function setItemWithExpiry(key, value, ttl) {
    const now = new Date();
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
}

function getItemWithExpiry(key) {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
        return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }
    return item.value;
}
*/

function debounceWhileSaving(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function formatDate(date) {
    const pad = (num) => num < 10 ? '0' + num : num;
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function validatorShowHideInscrutions(validatorInstructionsStatus) {
    $(document).find('body .card:not(.always-shown-vtb)').each(function () {
        let card = $(this);
        let containsInputVideoAudio = card.find(':input, textarea, table video, audio').length > 0;
        let elementsAreHiddenForValidator = card.find('.hide-instructions-for-validator').length > 0;

        if (validatorInstructionsStatus == 'shown' || elementsAreHiddenForValidator) {
            card.find('.hide-instructions-for-validator').fadeIn().removeClass('hide-instructions-for-validator');

            card.show();

            $('.validatorShowHideInscrutions')
                .attr('title', I18n.t("validation_toolbox.hide_instruction"))
                .find('.far.fa-eye')
                .removeClass('far fa-eye')
                .addClass('fas fa-eye-slash')
                .end();
            document.querySelectorAll('.validatorShowHideInscrutions').forEach(function (tooltipElement) {
                let tooltipInstance = bootstrap.Tooltip.getInstance(tooltipElement);
                if (tooltipInstance) {
                    tooltipInstance.dispose();
                }
                new bootstrap.Tooltip(tooltipElement);
            });
        } else if (!containsInputVideoAudio) {
            card.find('img').not($('table img, img[src*="gif"]')).fadeOut().addClass('hide-instructions-for-validator');
            card.find('img[src*="gif"]').fadeIn().removeClass('hide-instructions-for-validator');
            card.find('p').not($('table p, #can-be-hidden-by-vtb p, .can-be-pinned-by-validator p, p:has(img[src*=".gif"]), p:has(audio), p:has(video)')).fadeOut().addClass('hide-instructions-for-validator');
            card.find('p ~ ul').not($('table ul, ul.playback-speed')).fadeOut().addClass('hide-instructions-for-validator');
            card.find('ol').not($('table ol')).fadeOut().addClass('hide-instructions-for-validator');
            card.find('h2').not($('table h2')).fadeOut().addClass('hide-instructions-for-validator');
            card.find('video').not($('table video')).fadeOut().addClass('hide-instructions-for-validator');
            card.find('div#can-be-hidden-by-vtb').fadeOut().addClass('hide-instructions-for-validator');


            $.when(card.find(':animated').promise()).then(function() {
                let relevantChildren = card.find('.form-row > *');
                let visibleNonValidatorChildren = relevantChildren.filter(':visible').not('.hide-instructions-for-validator');

                card.find('.bg-light').addClass('test');
                if (visibleNonValidatorChildren.length === 0) {
                    card.hide();
                } else {
                    card.show();
                }
            });

            $('.validatorShowHideInscrutions')
                .attr('title', I18n.t("validation_toolbox.show_instruction"))
                .find('.fas.fa-eye-slash')
                .removeClass('fas fa-eye-slash')
                .addClass('far fa-eye')
                .end();
            document.querySelectorAll('.validatorShowHideInscrutions').forEach(function (tooltipElement) {
                let tooltipInstance = bootstrap.Tooltip.getInstance(tooltipElement);
                if (tooltipInstance) {
                    tooltipInstance.dispose();
                }
                new bootstrap.Tooltip(tooltipElement);
            });
        }

    });
}

function validatorShowHideDefinedInscrutions(instructionVisibilityStatus) {
    var $element = $('#can-be-hidden-by-vtb');

    if (instructionVisibilityStatus == 'shown') {
        $element.fadeIn();
    } else {
        $element.fadeOut();
    }
}

function validatorPlaybackSpeed(playbackSpeed) {
    localStorage.setItem(gon.user + '_ValidatorPlaybackSpeed', parseFloat(playbackSpeed));
    $('body .card').each(function () {
        let containsInputVideoAudio = $(this).find('video, audio');
        if (containsInputVideoAudio.length > 0) {
            $(this).find('video, audio').each(function () {
                this.playbackRate = parseFloat(playbackSpeed);
            });
        }
    });
}

function validatorImageSize(imgSize) {
    if (imgSize == 'reset') {
        localStorage.removeItem(gon.user + '_ValidatorImageSize');
        $('body label + div > *').each(function () {
            $('img').each(function () {
                $(this).removeAttr('style');
            });
        });
    } else {
        localStorage.setItem(gon.user + '_ValidatorImageSize', imgSize);
        $('body label + div > *').each(function () {
            $('img').each(function () {
                $(this).width(imgSize).height('auto');
            });
        });
    }
}

function getCommentListForValidatorModal() {
    var storedLabels = JSON.parse(localStorage.getItem(gon.user + '_' + node_config_id + '_validator_notAcceptedComments'));
    if (storedLabels) {
        var $validatorCommentsModalList = $('<ul class="list-group">');
        $.each(storedLabels, function (index, value) {
            var $ModalCommentList = $('<li class="d-flex flex-row list-group-item justify-content-between align-items-center"></li>');
            var $ModalCommentLabel = $('<div class="label-text"  contenteditable="true"></div>').text(value);
            var $ModalDeleteCommentBtn = $('<a href="#" class="btn btn-sm delete-validation-comment rounded-circle border-0"><i class="far fa-trash-alt"></i></a>');

            $ModalCommentList.append($ModalCommentLabel).append($ModalDeleteCommentBtn);
            $validatorCommentsModalList.append($ModalCommentList);
        });
        $('#validatorToolboxModal .modal-body').empty();
        $('#validatorToolboxModal .modal-body').html($validatorCommentsModalList);

        var $addNewCommentBtn = $('<a href="#" class="btn btn-primary add-comment-btn">' + I18n.t('validation_toolbox.modal_addnewcomment') + '</a>');
        $('#validatorToolboxModal .modal-body').append($addNewCommentBtn);

        $addNewCommentBtn.on('click', function (e) {
            e.preventDefault();
            var $newCommentLi = $('<li class="d-flex flex-row list-group-item justify-content-between align-items-center"></li>');
            var $newCommentLabel = $('<div class="label-text" contenteditable="true"></div>');
            var $newDeleteCommentBtn = $('<a href="#" class="btn btn-sm delete-validation-comment rounded-circle border-0"><i class="far fa-trash-alt"></i></a>');
            $newCommentLi.append($newCommentLabel).append($newDeleteCommentBtn);
            $validatorCommentsModalList.append($newCommentLi).find('.label-text').focus();
        });
    }
    $('#validatorToolboxModal .modal-footer .modal-submit-button-wrapper').html('<a href="#" type="button" class="btn btn-primary" id="SavevalidatorToolboxModal" data-bs-dismiss="modal">' + I18n.t("validation_toolbox.modal_save") + '</a>');
}


function appendCommentListToValidationToolbox() {
    if (localStorage.getItem(gon.user + '_' + node_config_id + '_validator_notAcceptedComments') == null) {
        const now = new Date();
        const toolboxListCreationtimeStamp = formatDate(now);

        var labels = $('.validation-declination-reasons input[type="checkbox"]').siblings('label');
        var labelTexts = [];
        labels.each(function () {
            if ($(this).text() != null) {
                labelTexts.push($(this).text().trim());
            }
        });
        localStorage.setItem(gon.user + '_' + node_config_id + '_validator_notAcceptedComments', JSON.stringify(labelTexts));
        localStorage.setItem(gon.user + '_' + node_config_id + '_validator_comments_lastUpdate', toolboxListCreationtimeStamp);
    }
    var storedLabels = JSON.parse(localStorage.getItem(gon.user + '_' + node_config_id + '_validator_notAcceptedComments'));
    if (storedLabels) {
        var $select = $('<select class="form-control selectpicker" id="validator_notAcceptedComments_dropdown" multiple data-live-search="true" data-width="100%" data-selected-text-format="count" title="'+ I18n.t("validation_toolbox.choose_a_comment") +'">');
        $.each(storedLabels, function (index, value) {
            $select.append($('<option>').text(value).val(value));
        });
        $('body #toolbox-validation-comments-selection').html($select);
        $('#validator_notAcceptedComments_dropdown.selectpicker').selectpicker();
    }
    $('#toolbox-validation-comments-selection .selectpicker').on('show.bs.select', function () {
        var currentScroll = $(window).scrollTop();
        $(window).one('scroll', function () {
            $(window).scrollTop(currentScroll);
        });
    });
    attachSelectpickerEventListener();
}

function appendMainValidationToolSections() {
    var commentBoxContainer = $('.validation-tool-comment-box');
    var storedData = JSON.parse(localStorage.getItem(gon.user + '_' + node_config_id + '_' + job_task_id + '_commentSectionsData')) || {};

    if (commentBoxContainer.length > 0) {
        commentBoxContainer.each(function (index) {
            var $thisCommentBox = $(this);
            var commentBoxId = index + 1;

            var $listOfComments = $('<ul class="ps-3 m-0"></ul>');

            if (storedData[commentBoxId]) {
                $.each(storedData[commentBoxId], function (_, storedComment) {
                    $listOfComments.append($('<li></li>').text(storedComment));
                });
                if (!$('#validator-comments-reminder').hasClass('show')) {
                    $('#validator-comments-reminder').addClass('show');
                }
            } else {
                $listOfComments.append($('<li></li>'));
            }

            let interpolation_params = { TaskNumber: commentBoxId };
            var commentBoxContainer_title = I18n.t("validation_toolbox.task_n_validation", interpolation_params);

            var $elementsToolboxHTMLContent = $('<div>').append(
                $('<p>').addClass('text-primary fw-light').text(commentBoxContainer_title),
                $('<div>').addClass('row mb-3').append(
                    $('<div>').addClass('col').attr('id', 'toolbox-validation-comments-selection')
                ),
                $('<div>').addClass('row').append(
                    $('<div>').addClass('col').append(
                        $('<div>').addClass('m-0 form-control bg-white border-0').attr('id', 'toolbox-element-validation-comments').attr('contenteditable', 'true').attr('data-comment-box-id', commentBoxId).append(
                            $('<p>').text($thisCommentBox.closest('.card-body').find('label:first').text() + ': '),
                            $listOfComments
                        )
                    )
                )
            );

            $thisCommentBox.html($elementsToolboxHTMLContent).attr('data-element-box-id', commentBoxId);
        });

        appendCommentListToValidationToolbox();
    }
}

function resetMainValidationToolComment(){
    localStorage.removeItem(gon.user + '_' + node_config_id + '_validator_notAcceptedComments');
    appendCommentListToValidationToolbox();
    getCommentListForValidatorModal();

    $('#toolbox-validation-comments-selection.selectpicker').selectpicker();
    attachSelectpickerEventListener();
}

function attachSelectpickerEventListener() {
    $('#toolbox-validation-comments-selection .selectpicker').on('change.bs.select', function(e) {
        let commentBoxId = $(this).closest('.validation-tool-comment-box').attr('data-element-box-id');
        var $destinationTextArea = $(this).closest('.validation-tool-comment-box').find('#toolbox-element-validation-comments ul');
        var $selectedOptions = $(this).val() || [];
        let storageData = JSON.parse(localStorage.getItem(gon.user + '_' + node_config_id + '_' + job_task_id + '_commentSectionsData')) || {};
        if (!Array.isArray(storageData[commentBoxId])) {
            storageData[commentBoxId] = [];
        }

        let newComments = $selectedOptions.filter(function(comment) {
            return !storageData[commentBoxId].includes(comment);
            console.log(`## setting up newComments`);
        });

        if (newComments.length > 0) {
            console.log(`## newComments.length is > 0`);
            $destinationTextArea.find('li').filter(function() {
                return $(this).text().trim() === '';
            }).remove();
            newComments.forEach(function (comment) {
                var $commentItem = $('<li></li>').text(comment);
                $destinationTextArea.append($commentItem);
                storageData[commentBoxId].push(comment);
                console.log(`## comment: ${comment}`);
            });
            localStorage.setItem(gon.user + '_' + node_config_id + '_' + job_task_id + '_commentSectionsData', JSON.stringify(storageData));
            $('#validator-comments-reminder').addClass('show');
        }
    });
}


function appendMainValidationToolCollectCommentSections() {
    var collectCommentContainer = $('.validation-tool-comments-collect-box');
    var validatorInfoNotice = $(`
        <div class="alert alert-warning alert-dismissible fade" id="validator-comments-reminder" role="alert">
          <ul class="ps-3 mb-0"><li>Please ensure that you have inserted all your comments before submitting the job.</li></ul>
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    `);
    if (collectCommentContainer && $('.validation-tool-comment-box').length > 0) {
        collectCommentContainer.html('<div class="row align-items-center">' +
            '<div class="col-auto">' +
            '<p class="text-primary fw-light m-0 lh-1">'+ I18n.t("validation_toolbox.collect_allValidation_1stline") +'<br>'+ I18n.t("validation_toolbox.collect_allValidation_2ndline") +'</p>' +
            '</div>' +
            '<div class="col-auto d-flex flex-row">' +
            '<a href="#" class="btn btn-primary collectAllValidationsToolboxComments">'+ I18n.t("validation_toolbox.add_to_my_final_comment") +'</a>' +
            '<a href="#" class="btn btn-outline-primary modalAllValidationsToolboxComments" data-bs-toggle="modal" data-bs-target="#validatorToolboxModal">'+ I18n.t("validation_toolbox.show_before_adding") +'</a>' +
            '</div>' +
            '</div>');
        collectCommentContainer.closest('.card-body').append(validatorInfoNotice);
    }

}


function getAllCommentsForValidatorToTextarea(dataSource) {
    var $finalReviewerCommentTextarea = $('[id*="_review_comment"]');
    var finalValidatorComment = ""
    if (dataSource){
        finalValidatorComment = $('#ModalValidatorFinalCommentsTextarea').text();
    } else {
        var $validationsComment = $('.validation-tool-comment-box #toolbox-element-validation-comments');
        var allValidations = [];
        var isFirstParagraph = true;

        allValidations.push(I18n.t("validation_toolbox.thanks_text_comments") + '\n\n');
        $validationsComment.find('li').filter(function() {
            return $(this).text().trim() === '';
        }).remove();
        $validationsComment.each(function () {
            if ($(this).find('li').length > 0 ) {
                var $commentBox = $(this);
                $commentBox.find('p, li').each(function () {
                    var $element = $(this);
                    var commentText = $element.text().trim();
                    if (commentText) {
                        if ($element.is('p')) {
                            if (isFirstParagraph) {
                                allValidations.push(commentText + '\n');
                                isFirstParagraph = false;
                            } else {
                                allValidations.push('\n' + commentText + '\n');
                            }
                        } else if ($element.is('li')) {
                            allValidations.push(commentText + '\n');
                        }
                    }
                });
            }
        });
        finalValidatorComment = allValidations.join('');
    }
    if ($finalReviewerCommentTextarea.val() !== null && $finalReviewerCommentTextarea.val().trim() !== "") {
        var isConfirmed = confirm(I18n.t('validation_toolbox.prompt_collect_comments'));
        if (isConfirmed) {
            $finalReviewerCommentTextarea.val(finalValidatorComment);
            localStorage.setItem(gon.user + '_' + node_config_id + '_' + job_task_id + '_validator_allComments', finalValidatorComment);
            $finalReviewerCommentTextarea.height(300);
        } else {
            return false;
        }
    } else {
        $finalReviewerCommentTextarea.val(finalValidatorComment);
        localStorage.setItem(gon.user + '_' + node_config_id + '_' + job_task_id + '_validator_allComments', finalValidatorComment);
    }
}


function getAllCommentsForValidatorModal() {
    var $finalReviewerModalTextarea = $('<div id="ModalValidatorFinalCommentsTextarea" class="form-control h-auto" contenteditable="true"></div>');
    var $validationsComment = $('.validation-tool-comment-box #toolbox-element-validation-comments');
    var allValidations = [];
    var isFirstParagraph = true;

    allValidations.push(I18n.t("validation_toolbox.thanks_text_comments") + '\n\n');
    $validationsComment.find('li').filter(function() {
        return $(this).text().trim() === '';
    }).remove();
    $validationsComment.each(function () {
        if ($(this).find('li').length > 0) {
            var $commentBox = $(this);
            $commentBox.find('p, li').each(function () {
                var $element = $(this);
                var commentText = $element.text().trim();
                if (commentText) {
                    if ($element.is('p')) {
                        if (isFirstParagraph) {
                            allValidations.push(commentText + '\n');
                            isFirstParagraph = false;
                        } else {
                            allValidations.push('\n' + commentText + '\n');
                        }
                    } else if ($element.is('li')) {
                        allValidations.push(commentText + '\n');
                    }
                }
            });
        }
    });
    var finalValidatorComment = allValidations.join('');
    $('#validatorToolboxModal #manage-validation-comment').html($finalReviewerModalTextarea.html(finalValidatorComment));
    $('#validatorToolboxModal .modal-footer .modal-submit-button-wrapper').html('<a href="#" type="button" class="btn btn-primary collectAllValidationsToolboxComments" data-bs-dismiss="modal">'+ I18n.t("validation_toolbox.add_to_my_final_comment") +'</a>');
}


function hideValidatorToolbox(shouldShow) {
    if (shouldShow != undefined) {
        $('#validator-toolbox').addClass('hidden');
    } else {
        if ($('#validator-toolbox').hasClass('hidden')) {
            $('#validator-toolbox').removeClass('hidden');
            localStorage.setItem(gon.user + '_validator-toolbox', 'shown');
        } else {
            $('#validator-toolbox').addClass('hidden');
            localStorage.setItem(gon.user + '_validator-toolbox', 'hidden');
        }
    }
}

function appendPinnedContent(text, pinnedId) {
    var prefix = pinnedId.includes('age') ? "Age: " : pinnedId.includes('countryCode') ? 'Country Code: ' :  pinnedId.includes('openTaskNbr') ? 'Open Jobs: ' :'';
    var spanHtml = `<div class="badge rounded-pill badge-validator-primary validator-pinned-infos txt4" data-badge-pinned="${pinnedId}">
                        <span contenteditable="true">${prefix}${text}</span>
                        <a href="#" class="remove-pinned-info">
                            <i class="fas fa-times"></i>
                        </a>
                    </div>`;
    $('#jobsInfoHeader').append(spanHtml);
}


$(document).on('load turbolinks:load', function() {
    if (window.node_config_id) {
        $(document).find('body').addClass('validation-job');
        $(document).on('click', '#playbackSpeedControls a', function (e) {
            let playbackSpeed = $(this).attr('data-feedback-speed');
            validatorPlaybackSpeed(playbackSpeed);
            $(this).closest('ul').find('.list-inline-item').removeClass('active');
            $(this).closest('.list-inline-item').addClass('active');
            e.preventDefault();
        });

        $(document).on('click', '#imagesSizeControls a', function (e) {
            e.preventDefault();
            let imgSize = $(this).data('images-size');
            validatorImageSize(imgSize);
            $(this).closest('ul').find('.list-inline-item').removeClass('active');
            $(this).closest('.list-inline-item').addClass('active');
        });

        $(document).on('click', '#validatorCommentModalButton', function (e) {
            getCommentListForValidatorModal();
        });

        $(document).on('click', '.modalAllValidationsToolboxComments', function (e) {
            e.preventDefault();
            getAllCommentsForValidatorModal();
        });

        $(document).on('click', '.collectAllValidationsToolboxComments', function (e) {
            e.preventDefault();
            if ($(this).attr('data-bs-dismiss')) {
                getAllCommentsForValidatorToTextarea('forModal');
            } else {
                getAllCommentsForValidatorToTextarea();
            }
        });

        $(document).on('mouseover', '#playbackSpeedToggle', function(e) {
            var dropdownMenu = $(this).children(".dropdown-menu");
            if(dropdownMenu.is(":visible")) {
                dropdownMenu.parent().toggleClass("open");
            }
        });

        $(document).on('click', '.delete-validation-comment', function(e) {
            e.preventDefault();
            $(this).closest('li').remove();
        });


        attachSelectpickerEventListener()

        if ($('.validation-tool-comment-box').length == 0){
            $('.for-validatorCommentModalButton').hide();
        }

        if(localStorage.getItem(gon.user+'_ValidatorPlaybackSpeed')!=undefined){
            validatorPlaybackSpeed(localStorage.getItem(gon.user+'_ValidatorPlaybackSpeed'));
            $('#playbackSpeedControls li').removeClass('active');
            $('#playbackSpeedControls a').each(function (){
                if ($(this).attr('data-feedback-speed') == localStorage.getItem(gon.user+'_ValidatorPlaybackSpeed')){
                    $(this).closest('li').addClass('active');
                }
            });
        }

        if(localStorage.getItem(gon.user+'_ValidatorImageSize')!=undefined){
            validatorImageSize(localStorage.getItem(gon.user+'_ValidatorImageSize'));
            $('#imagesSizeControls li').removeClass('active');
            $('#imagesSizeControls a').each(function (){
                if ($(this).attr('data-images-size') == localStorage.getItem(gon.user+'_ValidatorImageSize')){
                    $(this).closest('li').addClass('active');
                }
            });
        }

        if (localStorage.getItem(gon.user + '_' + node_config_id + '_' + job_task_id + '_validator_allComments') != null && ($('textarea[id*="_review_comment"]').length || $('#ModalValidatorFinalCommentsTextarea').length)) {
            $('textarea[id*="_review_comment"]').val(localStorage.getItem(gon.user + '_' + node_config_id + '_' + job_task_id + '_validator_allComments')).height(300);
        }

        if ($('textarea[id*="_review_comment"]').length) {
            var saveFinalCommentToLocalStorage = debounceWhileSaving(function () {
                var textarea = $(this);
                localStorage.setItem(gon.user + '_' + node_config_id + '_' + job_task_id + '_validator_allComments', textarea.val());
            }, 1000); // 1000ms between each change

            $('textarea[id*="_review_comment"]').on('keydown', saveFinalCommentToLocalStorage)
        }

        if (localStorage.getItem(gon.user + '_' + node_config_id + '_pinned_content')) {
            var pinnedContentText, pinnedId = ""
            var pinnedContents = localStorage.getItem(gon.user + '_' + node_config_id + '_pinned_content').split(';');
            pinnedContents.forEach(function(pinnedContent) {
                if (pinnedContent.includes('dynamic_')){
                    pinnedContentText = $(`.can-be-pinned-by-validator[data-to-pinn="${pinnedContent}"]`).text().trim();
                    pinnedId = pinnedContent;
                } else {
                    pinnedContentText = pinnedContent;
                }
                if (pinnedContentText.trim() !== '') {
                    appendPinnedContent(pinnedContentText, pinnedId);
                }
            });
        }

        if (localStorage.getItem(gon.user+'_validator-toolbox') != null && localStorage.getItem(gon.user+'_validator-toolbox') == 'hidden'){
            hideValidatorToolbox('hidden');
        }

        $('.can-be-pinned-by-validator').each(function () {
            var $this = $(this);
            var dataToPinn = $this.attr('data-to-pinn');
            if ($this.find('.pin-to-jobsInfoHeader').length == 0) {
                var localStorageData = localStorage.getItem(gon.user + '_' + node_config_id + '_pinned_content');
                var showBtnStatus = "";
                if (localStorageData != null && localStorageData.includes(dataToPinn)) {
                    showBtnStatus = "display: none";
                }
                var $buttonHtml = `<a href="#" role="button" class="btn btn-sm btn-primary pin-to-jobsInfoHeader rounded-circle my-0" style="${showBtnStatus}"><i class="fas fa-plus"></i></a>`;
                $this.append($buttonHtml);
            }
        });

        $(document).find('.card:not(.always-shown-vtb)').each(function(){
            if (!$(this).hasClass('bg-light') && !$(this).hasClass('validation-card-wrapper') && ($(this).find('.form-row').children().length <= 0)){
                $(this).addClass('d-none');
            }
        })
    }

});

$(document).on('load turbolinks:load', function() {

    if (window.batch_config_updated_at) {
        var shouldShowModal = false;
        var lastUpdate = localStorage.getItem(gon.user + '_' + node_config_id + '_validator_comments_lastUpdate');
        var notAcceptedComments = localStorage.getItem(gon.user + '_' + node_config_id + '_validator_notAcceptedComments');

        if ((lastUpdate == null && notAcceptedComments != null) ||
            (lastUpdate != null && new Date(window.batch_config_updated_at) > new Date(lastUpdate))) {
            $('#resetCommentList').removeClass('d-none');
            $('a#validatorCommentModalButton').append('<span class="badge-pulse"><small></small></span>');
            //shouldShowModal = window.confirm(I18n.t('validation_toolbox.reset_validation_comment_alert'));
            new Noty({
                type: 'error',
                layout: 'topRight',
                theme: 'bootstrap-v4',
                text: '<div class="row">'+
                        '<div class="col">'+
                            '<strong class="txt2">' +
                                '<i class="fas fa-exclamation-triangle"></i> ' +
                                I18n.t("validation_toolbox.reset_validation_comment_alert_title") +
                            '</strong>' +
                            '<p class="txt2 my-2 text-white">' +
                                I18n.t("validation_toolbox.reset_validation_comment_alert") +
                            '</p>' +
                            '<a href="#" class="btn btn-light text-danger mx-0">' +
                                I18n.t("validation_toolbox.reset_validation_comment_alert_btn") +
                            '</a>' +
                        '</div>'+
                    '</div>',
                animation: {
                    open : 'animated fadeInRight',
                    close: 'animated fadeOutRight'
                },
            }).on('onClick', function () {
                getCommentListForValidatorModal();
                new bootstrap.Modal('#validatorToolboxModal').show();
            }).show();
        } else {
            $('#resetCommentList').remove();
        }
        /*
        if (shouldShowModal) {
            getCommentListForValidatorModal();

        }
        */
    } else {
        $('#resetCommentList').remove();
    }

    if (window.node_config_id) {
        if (localStorage.getItem(gon.user + '_' + node_config_id + '_validator_instructions') != null) {
            var validatorInstructionsStatus = localStorage.getItem(gon.user + '_' + node_config_id + '_validator_instructions')
            validatorShowHideDefinedInscrutions(validatorInstructionsStatus);
            validatorShowHideInscrutions(validatorInstructionsStatus);
        }

        var saveElementValidationCommentToLocalStorage = debounceWhileSaving(function (dataToStore) {
            localStorage.setItem(gon.user + '_' + node_config_id + '_' + job_task_id + '_commentSectionsData', JSON.stringify(dataToStore));
        }, 1000); // 1000ms between each change
    }

    $('#validatorToolboxModal').on('hidden.bs.modal', function () {
        $(this).find('.modal-body').empty();
    });

    $(document).on('click', '#resetCommentList', function (e) {
        $(this).addClass('d-none');
        // $(this).tooltip('hide');
        $(this).fadeOut('fast', function() {
            resetMainValidationToolComment();
            $(this).remove();
            $(document).find('a#validatorCommentModalButton .badge-pulse').remove();
        });
    });

    $(document).on('click', '.hide-validator-toolbox', function (e) {
        e.preventDefault();
        hideValidatorToolbox();
    });

    $(document).on('click', '.validatorShowHideInscrutions', function (e) {
        e.preventDefault();
        let validatorInstructionsStatus = $("body").find('.hide-instructions-for-validator').length > 0 ? 'shown' : 'hidden'
        localStorage.setItem(gon.user + '_' + node_config_id + '_validator_instructions', validatorInstructionsStatus);
        validatorShowHideDefinedInscrutions(validatorInstructionsStatus);
        validatorShowHideInscrutions(validatorInstructionsStatus);
    });

    $(document).on('click', '.pin-to-jobsInfoHeader', function(e) {
        e.preventDefault();
        var parentText = $(this).closest('.can-be-pinned-by-validator').text().trim();
        var pinnedElementId = $(this).closest('.can-be-pinned-by-validator').attr('data-to-pinn');
        appendPinnedContent(parentText, pinnedElementId);
        var existingPinnedContent = localStorage.getItem(gon.user + '_' + node_config_id + '_pinned_content');
        if (existingPinnedContent) {
            existingPinnedContent += pinnedElementId + ';';
        } else {
            existingPinnedContent = pinnedElementId + ';';
        }
        $(this).fadeOut(100);
        localStorage.setItem(gon.user + '_' + node_config_id + '_pinned_content', existingPinnedContent);
    });


    $(document).on('keyup', '#toolbox-element-validation-comments', function(e) {
        let commentBoxId = $(this).closest('.validation-tool-comment-box').attr('data-element-box-id');
        var $commentList = $(this).find('ul');
        var $commentListTitle = $(this).find('p').text();
        var storedData = JSON.parse(localStorage.getItem(gon.user + '_' + node_config_id + '_' + job_task_id + '_commentSectionsData')) || {};
        var validationElementsComments = [];
        $commentList.find('li').each(function () {
            var commentText = $(this).text().trim();
            if (commentText !== '') {
                validationElementsComments.push(commentText);
            }
        });
        storedData[commentBoxId] = validationElementsComments;
        saveElementValidationCommentToLocalStorage(storedData);
    });

    $(document).on('keyup', '#toolbox-element-validation-comments', function(e) {
        let $commentBox = $(this);
        let $commentList = $commentBox.find('ul');
        let $commentListTitle = $commentBox.find('p').text();

        if ($commentList.length === 0 || $commentList.find('li').length === 0) {
            $commentBox.append('<ul class="ps-3 m-0"><li></li></ul>');
        }
        if ($commentBox.find('p').text().trim() === "") {
            $commentBox.find('p').html($commentBox.closest('.card-body').find('label:first').text() + ':');
        }
        if ($commentBox.find('p').length === 0) {
            $commentBox.prepend(`<p><strong>${$commentBox.closest('.card-body').find('label:first').text()}:</strong></p>`);
        }
    });

    $(document).on('paste', '.validator-pinned-infos span[contenteditable]', function(e) {
        var pastedText = '';
        if (e.originalEvent.clipboardData && e.originalEvent.clipboardData.getData) {
            pastedText = e.originalEvent.clipboardData.getData('text/plain');
        } else if (window.clipboardData && window.clipboardData.getData) {
            pastedText = window.clipboardData.getData('text/plain');
        }
        document.execCommand('insertText', false, pastedText);
    });

    $(document).on('blur', '.validator-pinned-infos span[contenteditable]', function() {
        var updateIsDone = false;
        var updatedPinnedContent = "";
        var currentPinnedContent = localStorage.getItem(gon.user + '_' + node_config_id + '_pinned_content', updatedPinnedContent);
        var updatedPinnedId = $(this).closest('.validator-pinned-infos').addClass('with-updated-text').attr('data-badge-pinned');
        if (currentPinnedContent.length > 0) {
            var contentPinnedArray = currentPinnedContent.split(';');
            for (var i = 0; i < contentPinnedArray.length; i++) {
                if (contentPinnedArray[i].includes(updatedPinnedId) && !updateIsDone) {
                    contentPinnedArray[i] = $(this).text().trim();
                    updateIsDone = true;
                }
            }
            updatedPinnedContent = contentPinnedArray.join(';');
        }
        localStorage.setItem(gon.user + '_' + node_config_id + '_pinned_content', updatedPinnedContent);
    });

    $(document).on('click', '.remove-pinned-info', function(e) {
        var badgePinned = $(this).closest('.badge').attr("data-badge-pinned");
        $(document).find('.can-be-pinned-by-validator[data-to-pinn="' + badgePinned + '"]').find('.pin-to-jobsInfoHeader').fadeIn(300);
        e.preventDefault();
        $(this).closest('.badge').remove();
        var updatedPinnedContent = "";
        $('.validator-pinned-infos').each(function() {
            if ($(this).hasClass('with-updated-text')){
                updatedPinnedContent += $(this).find('span[contenteditable]').text().trim() + ';';
            } else {
                updatedPinnedContent += $(this).attr('data-badge-pinned') + ';';
            }
        });
        localStorage.setItem(gon.user + '_' + node_config_id + '_pinned_content', updatedPinnedContent);
    });

    $(document).on('click', '#SavevalidatorToolboxModal', function(e) {
        e.preventDefault();
        var commentList = $('#validatorToolboxModal .modal-body#manage-validation-comment .list-group-item');
        var commentListToSave = [];
        commentList.each(function() {
            if ($(this).find('.label-text').text().trim() != "") {
                commentListToSave.push($(this).find('.label-text').text().trim());
            }
        });
        localStorage.setItem(gon.user+'_'+ node_config_id +'_validator_notAcceptedComments', JSON.stringify(commentListToSave));
        appendCommentListToValidationToolbox();
        attachSelectpickerEventListener();
        localStorage.setItem(gon.user + '_' + node_config_id + '_validator_comments_lastUpdate', formatDate(new Date()));
    });
    if (window.node_config_id) {
        appendMainValidationToolCollectCommentSections();
        appendMainValidationToolSections();
    }
});
