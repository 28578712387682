function initializeSortable() {
    $(".ranking-sort-container").disableSelection();
    $(".ranking-sort-container").sortable({
        axis: "y",
        cursor: "move",
        revert: 200,
        start: function(event, ui) {
            ui.item.addClass('dragging');
        },
        stop: function(event, ui) {
            ui.item.removeClass('dragging');
            updateRankingIndices(ui.item.closest('.ranking-sort-container'));
        }
    });
}

function bindSortableEventHandlers() {
    $(document).on('click', '.ranking-modal-draggable-area', function() {
        let targetModal = $(`${$(this).attr('data-bs-target')}`);
        targetModal.find('.ranking-modal-options-card')
            .data('change-target', this.id)
            .data('element-source', $(this).data('count-id'));
    });


    $(document).on('click', '.ranking-modal-options-card', function() {
        let targetOptionId = $(this).data('change-target');
        let targetOption = $(`#${targetOptionId}`);
        let rankingText = $(this).find('.ranking-text').text().trim();
        let rankingValue = $(this).find('.ranking-value').text().trim();
        let mainModalOptionsCardContainer = $(this).closest('.modal-body')
        //handleLastOptionVisibility(mainModalOptionsCardContainer);
        updateRankingOption(targetOption, rankingText, rankingValue);
        $(this).fadeOut(200, function() {
            $(this).remove();
        });
    });
}

function updateRankingIndices(sortableContainer) {
    sortableContainer.find('.ranking-modal-draggable-area').each(function(index) {
        $(this).find('.element-index').text(index + 1)
            .end().data('count-id', index + 1);
    });
}

function updateRankingOption(targetOption, rankingText, rankingValue) {
    if (!(targetOption instanceof jQuery)) {
        targetOption = $(targetOption);
    }
    targetOption.removeAttr('data-bs-toggle')
        .addClass('with_value')
        .find('.ranking_answer_text').removeClass('no_value_set').text(rankingText)
        .end().find('.element-index').removeClass('btn-outline-primary').addClass('btn-primary');
    let inputId = targetOption.attr('id').replace('raking_card_item', 'ranking_input');
    $(`#${inputId}`).val(rankingValue);
}

function handleLastOptionVisibility(modalBody) {
    let visibleOptions = modalBody.find('.ranking-modal-options-card:visible');
    if (visibleOptions.length <= 2) {
        visibleOptions.each(function() {
            let optionText = $(this).text().trim();
            let nextTargetOption = getNextAvailableRankingTarget(modalBody.closest('.modal'));
            if (nextTargetOption && $(nextTargetOption).hasClass('with_no_value')) {
                updateRankingOption($(nextTargetOption), optionText);
                $(this).fadeOut(500, function() {
                    $(this).remove();
                });
            }
        });
    }
}

function getNextAvailableRankingTarget(container) {
    let nextTarget = null;
    let rankingArea = $(`.${container.attr('aria-labelledby')}`);
    rankingArea.find('.ranking-modal-draggable-area').each(function() {
        if ($(this).find('.ranking_answer_text.no_value_set').length) {
            nextTarget = this;
            return false;
        }
    });
    return nextTarget;
}

window.initializeSortable = initializeSortable;
window.bindSortableEventHandlers = bindSortableEventHandlers;
window.updateRankingIndices = updateRankingIndices;
window.updateRankingOption = updateRankingOption;
//window.handleLastOptionVisibility = handleLastOptionVisibility;
window.getNextAvailableRankingTarget = getNextAvailableRankingTarget;
