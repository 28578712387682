$(document).on('load turbolinks:load', function() {

    'use strict';

    // ------------------------------------------------------- //
    // Animated Tabs
    // ------------------------------------------------------ //
	$(function () {
		var b = "fadeInLeft";
		var c;
		var a;
		d($("#animate-tab a"), $("#animate-tab-content"));

		function d(e, f, g) {
			e.click(function (i) {
				i.preventDefault();
				$(this).tab("show");
				var h = $(this).data("easein");
				if (c) {
					c.removeClass(a);
				}
				if (h) {
					f.find("div.active").addClass("animated " + h);
					a = h;
				} else {
					if (g) {
						f.find("div.active").addClass("animated " + g);
						a = g;
					} else {
						f.find("div.active").addClass("animated " + b);
						a = b;
					}
				}
				c = f.find("div.active");
			});
		}

		var popoverTriggerList = [].slice.call(document.querySelectorAll('a[data-bs-toggle="popover"]'));
		var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
			return new bootstrap.Popover(popoverTriggerEl);
		});

		$("a[rel=popover][data-bs-toggle='popover']").click(function (event) {
			event.preventDefault();
			var easein = $(this).data("easein");
			if (easein !== undefined) {
				$(this).next().removeClass(easein).addClass("animated " + easein);
			} else {
				$(this).next().addClass("animated " + defaultAnimation);
			}
		});
	});

});
