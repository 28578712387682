import 'bootstrap/dist/js/bootstrap.bundle';
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Turbolinks from 'turbolinks';
import './workplace/plugins'
import './workplace/application'
import 'bootstrap/scss/bootstrap';
import '../stylesheets/workplace/workplace.scss'
import './pages/registration.coffee'

Rails.start();
Turbolinks.start();

if (gon.is_analytics_allowed) {
    import('./workplace/firebase/fireinit'); //firebase
}

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
