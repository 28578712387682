$(document).on('load turbolinks:load', function() {
    function mailNavAttributeToggler(element, attribute, trueVal, falseVal) {
        if (trueVal === undefined) {
            trueVal = true;
        }
        if (falseVal === undefined) {
            falseVal = false;
        }
        if (element.attr(attribute) !== trueVal) {
            element.attr(attribute, trueVal);
        } else {
            element.attr(attribute, falseVal);
        }
    }


    const filterCount = (jQuery('[data-tag-filter]').not('[data-tag-filter="all"]')).length;

    function checkTags() {
        var activatedFilterCount = ((jQuery('[data-tag-filter].active').not('[data-tag-filter="all"]')).length);
        if (filterCount == activatedFilterCount) {
            jQuery('[data-tag-filter="all"]').addClass('active');
        }
    }

    checkTags();

    (function($) {
        jQuery('#messages-nav-burger-menu').on('click', function(e) {
            mailNavAttributeToggler(jQuery(this), 'aria-expanded', 'true', 'false');
            jQuery('.messages-nav').toggleClass('active');
        });
        jQuery('[data-bs-toggle="tab"]').on('click', function() {
            jQuery('#messages-content').addClass('active');
        });
        jQuery('#messages-content-back').on('click', function() {
            jQuery('#messages-content').removeClass('active');
        });




        jQuery('a[data-tag-filter]').on('click', function() {
            var elementToFilter = jQuery(this).attr('data-tag-filter');

            switch (elementToFilter) {
                case 'all':
                    if (jQuery(this).hasClass('active')) {
                        jQuery('a[data-tag-filter]').removeClass('active');
                        jQuery('li[data-tag]').toggle();
                    } else {
                        jQuery('a[data-tag-filter]').addClass('active');
                        jQuery('li[data-tag]').toggle();
                    }
                    break;

                default:
                    jQuery(this).toggleClass('active');
                    jQuery('li[data-tag="' + elementToFilter + '"').toggle();
                    break;
            }
            checkTags();
            // console.log(elementToFilter);
            // console.log('Count: ' + filterCount);
            // console.log('Active: ' + activatedFilterCount);
            return false;
        });

    })(jQuery);


    // ------------------------------------------------------- //
    // mailingFiler
    // ------------------------------------------------------ //
    function mailingFiler() {
        // Declare variables
        var input, filter, ul, li, a, i, txtValue;
        input = document.getElementById('msg-filter');
        filter = input.value.toUpperCase();
        ul = document.getElementById("messages-list");
        li = ul.getElementsByTagName('li');

        // Loop through all list items, and hide those who don't match the search query
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("a")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    }
});
