$(document).on('load turbolinks:load', function() {

    'use strict';

    // ------------------------------------------------------- //
    // Owl Carousel
    // ------------------------------------------------------ //
	$('.reviews').owlCarousel({
		nav: false,
		dots: true,
		loop: true,
		items: 1,
		smartSpeed: 800,
		//autoHeight:true,
		fluidSpeed: true
	});

});
