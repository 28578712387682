$(document).on 'ready turbolinks:load', ->
  if $('#workplace_jobs_index').length > 0
    window.jobsPage = new JobsPage($('#workplace_jobs_index'))


class JobsPage
  constructor: (root) ->
    @root = root

  bindHideLinks: =>
    @root.find('.hide-job-btn').on 'ajax:success', (e) ->
      $(e.target).closest('.jobInput').fadeOut('slow')
      $('#showHiddenJobsWrapper').removeClass('d-none')

    $('.btn-translator').on 'click', (e) ->
      e.preventDefault()
      $(this).prev('#dynamic-translation-wrapper').find('.dynamic-translation-text').slideToggle 'fast', ->
        if $(this).is(':hidden')
          $(this).parent('#dynamic-translation-wrapper').next('.btn-translator').text I18n.t('dynamic_translation_button.' + $(this).attr('id'))

    # will be refactored in http://redmine.clickworker.intern/issues/23783
    $('.stories-slider').owlCarousel({
      loop: false,
      margin: 30,
      autoplay: false,
      autoplayTimeout: 3000,
      autoplayHoverPause: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: false,
          touchDrag: true,
          mouseDrag: true,
          dots: true
        },
        992: {
          items: 2,
          nav: true,
          mouseDrag: true,
          dots: true
        },
        1440: {
          items: 3,
          nav: true,
          mouseDrag: true,
          dots: true
        }
      }
    })
    $('.owl-carousel').each ->
      $(this).data('owl.carousel').onResize()

# -------------------------------------------------------
      # Job list view toggler Grid/List
# ------------------------------------------------------
    cwwJobListView = localStorage.getItem(gon.user + '_CWWJobList')
    if cwwJobListView == 'ListView'
      $('body[id*="workplace_jobs"]').addClass 'list-view'
      $('.jobInput').addClass 'list-view'
      if $('.shorties_wrp').length > 0
        $('.shorties_wrp').addClass 'shorties-list-view'
      $('.display-filter-grid').removeClass 'active'
      $('.display-filter-list').addClass 'active'
      $('.page-header').addClass 'list-view-header'

    $(document).on 'click', '.display-filter-list', ->
      $('body[id*="workplace_jobs"]').addClass 'list-view'
      $('.jobInput').addClass 'list-view'
      if $('.shorties_wrp').length > 0
        $('.shorties_wrp').addClass 'shorties-list-view'
      $('.page-header').addClass 'list-view-header'
      $('.display-filter-grid').removeClass 'active pointer-events-none'
      $('.display-filter-list').addClass 'active pointer-events-none'
      localStorage.setItem gon.user + '_CWWJobList', 'ListView'
      clickSectionPos = $(this).parent().parent().attr('data-parent')
      $('html, body').animate scrollTop: $('#'+clickSectionPos).offset().top - 100, 150


    $(document).on 'click', '.display-filter-grid', ->
      $('body[id*="workplace_jobs"]').removeClass 'list-view'
      $('.jobInput').removeClass 'list-view'
      if $('.shorties_wrp').length > 0
        $('.shorties_wrp').removeClass 'shorties-list-view'
      $('.page-header').removeClass 'list-view-header'
      $('.display-filter-list').removeClass 'active pointer-events-none'
      $('.display-filter-grid').addClass 'active pointer-events-none'
      localStorage.setItem gon.user + '_CWWJobList', 'GridView'
      clickSectionPos = $(this).parent().parent().attr('data-parent')
      $('html, body').animate scrollTop: $('#'+clickSectionPos).offset().top - 100, 100
