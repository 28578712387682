import pattern1 from '../../../images/workplace/patterns/1.png'
import pattern2 from '../../../images/workplace/patterns/2.png'
import pattern3 from '../../../images/workplace/patterns/3.png'
import pattern4 from '../../../images/workplace/patterns/4.png'
import pattern5 from '../../../images/workplace/patterns/5.png'
import pattern6 from '../../../images/workplace/patterns/6.png'
import pattern7 from '../../../images/workplace/patterns/7.png'
import pattern8 from '../../../images/workplace/patterns/8.png'
import pattern9 from '../../../images/workplace/patterns/9.png'
import pattern10 from '../../../images/workplace/patterns/10.png'
import pattern11 from '../../../images/workplace/patterns/11.png'
jQuery(document).on('load turbolinks:load', function() {
    'use strict';

    moment.locale(I18n.locale);

    // ------------------------------------------------------- //
    // Dark-mode / Profile-Background / Acccessibility controller
    // ------------------------------------------------------ //
    jQuery(function() {
        var cwwTheme = "",
            cwwAccessibility = "",
            cwwProfileBackground = "",
            cwwSideNavState = "",
            cwwTheme = localStorage.getItem(gon.user+'_CWWtheme')? localStorage.getItem(gon.user+'_CWWtheme') : '',
            cwwAccessibility = localStorage.getItem(gon.user+'_cwwAccessibility')? localStorage.getItem(gon.user+'_cwwAccessibility') : '',
            cwwSideNavState = localStorage.getItem(gon.user+'_sideNavState'),
            cwwProfileBackground = localStorage.getItem(gon.user+'_CWWProfileBackground');

        if (cwwTheme == 'light') {
            jQuery('html').attr('data-bs-theme', 'light');
            jQuery('#darkMode').attr('data-bs-theme','light');
            jQuery('#darkMode span').text(I18n.t('data_theme.dark_mode'));
            jQuery('#darkMode i').removeClass('ico-sun-light-outline');
            jQuery('#darkMode i').addClass('ico-moon-dark-outline');
            gon.cwwTheme = 'light';
        } else if (cwwTheme == 'dark') {
            jQuery('html').attr('data-bs-theme', 'dark');
            jQuery('#darkMode').attr('data-bs-theme','dark');
            jQuery('#darkMode span').text(I18n.t('data_theme.light_mode'));
            jQuery('#darkMode i').removeClass('ico-moon-dark-outline');
            jQuery('#darkMode i').addClass('ico-sun-light-outline');
            gon.cwwTheme = 'dark';
        }
        if (cwwAccessibility == 'default') {
            jQuery('html').attr('data-accessibility', 'default');
            jQuery('li #accessToggler').removeClass('current');
            jQuery('#accessToggler[data-access-toggler=standard]').addClass('current');
        } else if (cwwAccessibility == 'small') {
            jQuery('html').attr('data-accessibility', 'small');
            jQuery('li #accessToggler').removeClass('current');
            jQuery('#accessToggler[data-access-toggler=smaller]').addClass('current');
        } else if (cwwAccessibility == 'big') {
            jQuery('html').attr('data-accessibility', 'big');
            jQuery('li #accessToggler').removeClass('current');
            jQuery('#accessToggler[data-access-toggler=bigger]').addClass('current');
        }

        if ((cwwSideNavState!='')&&(cwwSideNavState=='minimized')){
            !jQuery('nav.side-navbar').hasClass('locked') ? jQuery('nav.side-navbar').addClass('locked') : '';
            !jQuery('.default-sidebar nav.side-navbar').hasClass('shrinked') ? jQuery('nav.side-navbar').addClass('shrinked locked') : '';
            jQuery('.navbar-brand').addClass('shrinked');
            (jQuery('body:not(#sessions_new)')&&!jQuery('.default-sidebar #toggle-btn').hasClass('notactive')) ? jQuery('#toggle-btn').addClass('notactive') : '';
            (jQuery('body:not(sessions_new)')&&!jQuery('.default-sidebar #toggle-btn').hasClass('locked')) ? jQuery('#toggle-btn').addClass('locked') : '';
            (jQuery('body:not(sessions_new)')&&!jQuery('.default-sidebar #toggle-btn #lock-navbar').hasClass('active')) ? jQuery('#toggle-btn #lock-navbar').addClass('active') : '';
            jQuery('.default-sidebar #lock-navbar').hasClass('active')? jQuery('#lock-navbar').addClass('active') : '';
            !jQuery('.content-inner').hasClass('active') ? jQuery('.content-inner').addClass('active') : '';
        } else if ((cwwSideNavState!='')&&(cwwSideNavState=='maximized')){
            jQuery('.default-sidebar nav.side-navbar').hasClass('shrinked') ? jQuery('nav.side-navbar').removeClass('shrinked').addClass('locked').addClass('mouseWasOvered') : '';
            jQuery('.navbar-brand').removeClass('shrinked');
            (jQuery('body:not(sessions_new)')&&jQuery('.default-sidebar #toggle-btn').hasClass('notactive')) ? jQuery('#toggle-btn').removeClass('notactive').appendTo('.side-navbar') : '';
            (jQuery('body:not(sessions_new)')&&!jQuery('.default-sidebar #toggle-btn').hasClass('locked')) ? jQuery('#toggle-btn').addClass('locked') : '';
            jQuery('.default-sidebar #lock-navbar').addClass('active');
            jQuery('.content-inner').hasClass('active') ? jQuery('.content-inner').removeClass('active') : '';
        }

        if (cwwProfileBackground != '' && cwwProfileBackground != undefined && cwwProfileBackground != null) {
            jQuery(".bg-select > li").removeClass("active");
            jQuery("li[data-background='" + cwwProfileBackground + "']").addClass("active");
            var patterns = { '1': pattern1, '2': pattern2, '3': pattern3, '4': pattern4, '5': pattern5, '6': pattern6, '7': pattern7, '8': pattern8, '9': pattern9, '10': pattern10, '11': pattern11}
            var profiBg = "url(" + patterns[cwwProfileBackground] + ")"
            jQuery("#profile-cover-bg").css("background-image", profiBg);
        } else {
            jQuery(".bg-select > li").removeClass("active");
            jQuery("li[data-background='8']").addClass("active");
            jQuery("#profile-cover-bg").css("background-image", "url(" + pattern8 + ")");
        }


        jQuery('#darkMode').on('click', function(e) {
            jQuery('html').attr('data-bs-theme', function(index, attr) {
                attr == 'dark' ? attr = 'light' : attr = 'dark';
                if (attr == 'dark') {
                    jQuery('#darkMode span').text(I18n.t('data_theme.light_mode'));
                    jQuery('#darkMode i').removeClass('ico-moon-dark-outline');
                    jQuery('#darkMode i').addClass('ico-sun-light-outline');
                } else if (attr == 'light') {
                    jQuery('#darkMode span').text(I18n.t('data_theme.dark_mode'));
                    jQuery('#darkMode i').removeClass('ico-sun-light-outline');
                    jQuery('#darkMode i').addClass('ico-moon-dark-outline');
                }
                jQuery('html').attr('data-bs-theme', attr);
                jQuery('#darkMode').attr('data-bs-theme', attr);
                jQuery('#recaptchaWrapper').attr('data-bs-theme', attr);
                jQuery('.g-recaptcha').attr('data-bs-theme', attr);
                if (gon.user){
                    localStorage.setItem(gon.user + '_CWWtheme', attr);
                    gon.cwwTheme = attr;
                } else {
                    localStorage.setItem('last_CWWtheme_setting', attr);
                }
                return attr;
            });
        });

        jQuery('li #accessToggler').click(function(e) {
            var accessAttrValue = jQuery(this).attr('data-access-toggler');
            var accessValue = '';
            switch (accessAttrValue) {
                case 'smaller':
                    accessValue = 'small';
                    break;
                case 'standard':
                    accessValue = 'default';
                    break;
                case 'bigger':
                    accessValue = 'big';
                    break;

                default:
                    accessValue = 'default';
                    break;
            }
            jQuery('li #accessToggler').removeClass('current');
            jQuery('#accessToggler[data-access-toggler="' + accessAttrValue + '"]').addClass('current');
            jQuery('html').attr('data-accessibility', accessValue);
            if (gon.user) {
                localStorage.setItem(gon.user + '_cwwAccessibility', accessValue);
            } else {
                localStorage.setItem('last_cwwAccessibility_setting', accessValue);
            }
        });

        jQuery('input, textarea').each(function() {
            if ((!jQuery(this).prop('required'))&&(jQuery(this).val() != '')&&(!jQuery(this).hasClass('notempty'))) {
                jQuery(this).addClass('notempty');
            } else if ((!jQuery(this).prop('required'))&&(!jQuery(this).hasClass('notempty'))){
                jQuery(this).removeClass('notempty');
            }
        });

    });

    // ------------------------------------------------------- //
    // Dynamic Translation
    // ------------------------------------------------------ //
    $('.btn-translator').on('click', function(e){
        e.preventDefault();
        $(this).prev('#dynamic-translation-wrapper').find('.dynamic-translation-text').slideToggle('fast', function(){
            if ($(this).is(':hidden')) {
                $(this).parent('#dynamic-translation-wrapper').next('.btn-translator').text(I18n.t('dynamic_translation_button.' + $(this).attr('id')));
            }
        });
    });


    // ------------------------------------------------------- //
    // job filter
    // ------------------------------------------------------ //
    jQuery('.jobFilterCheckbox').each(function() {
        var jInput = jQuery(this),
            jLabel = jQuery(this).next('.job-filter-select'),
            jLabelVal = jLabel.html();

        jInput.click(function(e) {
            ((jQuery(this).attr('checked', true))||(jQuery(this).is(':checked'))) ? jQuery(this).removeAttr('checked') : jQuery(this).attr('checked', true);

            if ((jQuery(this).is(':checked'))||(jQuery(this).attr('checked', true))||(jQuery(this).attr('checked')=='checked')) {
                jQuery(this).next('.job-filter-select').html(jQuery(this).attr('id'));
            } else if ((jLabelVal != 'Type of job') || (jLabelVal != 'Payment') || (jLabelVal != 'Payabe in') || (jLabelVal != 'Available on')) {
                jLabel.html();
            }
        });
    });

    jQuery('input[type="checkbox"]#all').on('click', function(e) {
        jQuery('html').attr('data-bs-theme', function(index, attr) {
            attr == 'dark' ? attr = 'light' : attr = 'dark';
            if (attr == 'dark') {
                jQuery('#darkMode span').text('Light mode');
                jQuery('#darkMode i').removeClass('ico-moon-dark-outline');
                jQuery('#darkMode i').addClass('ico-sun-light-outline');
            } else if (attr == 'light') {
                jQuery('#darkMode span').text('Dark mode');
                jQuery('#darkMode i').removeClass('ico-sun-light-outline');
                jQuery('#darkMode i').addClass('ico-moon-dark-outline');
            }
            jQuery('#darkMode').attr('data-bs-theme', attr);
            localStorage.setItem(gon.user+'_CWWtheme', attr);
            return attr;
        });
    });

    // ------------------------------------------------------- //
    // jtoggler for know-how toggle page
    // ------------------------------------------------------ //
    jQuery('.jtoggler').jtoggler();
    jQuery(document).on('jt:toggled', function(event, target) {
        //console.log(event, target);
        //console.info(jQuery(target).prop('checked'))
    });
    jQuery(document).on('jt:toggled:multi', function(event, target) {
        //console.log(event, target);
        //console.info(jQuery(target).parent().index())
    });


    // ------------------------------------------------------- //
    // Filter Checkbox controller for Jobs Category
    // ------------------------------------------------------ //
    function removeFromArray(myArray) {
        var what, a = arguments,
            L = a.length,
            ax;
        while (L > 1 && myArray.length) {
            what = a[--L];
            while ((ax = myArray.indexOf(what)) !== -1) {
                myArray.splice(ax, 1);
            }
        }
        return myArray;
    }

    /************** FOR FILTERS DROPDOWN WITH LIMIT JUST ONE OPTIONS ***************/
    var filterlimit = 1;
    jQuery('.single-checkbox').on('change', function() {
        if (jQuery(this).siblings(':checked').length >= filterlimit) {
            this.checked = false;
        }
    });

    /************** JOBS CATEOGRY FILTER MAIN FILTER ***************/
    var jobsTypeValues = new Array(),
        payFilterValues = new Array(),
        payableValues = new Array(),
        devicesValues = new Array(),
        jStatusValues = new Array(),
        myValues = new Array();

    var jobsTypeBtn = jQuery('#type-of-jobs-btn').text(),
        payFilterBtn = jQuery('#payment-btn').text(),
        payableBtn = jQuery('#payable-in-btn').text(),
        devicesBtn = jQuery('#device-btn').text(),
        jStatusBtn = jQuery('#status-btn').text(),
        selectButtonTxt;

    /**** SHOW CHECKBOX TEXT INTO THE DROPDOWN BUTTON ****/
    function filterDropdownSelector() {
        var selectButton = jQuery(this).closest('.form-group').find('button').first();
        if (jQuery(this).is(":checked")) {
            switch (jQuery(this).attr("name")) {
                case "typeJfilter[]":
                    jobsTypeBtn = selectButton.text()
                    jobsTypeValues.push(jQuery(this).val());
                    myValues = jobsTypeValues;
                    break;
                case "payInFilter":
                    payFilterBtn = selectButton.text()
                    payFilterValues.push(jQuery(this).val());
                    myValues = payFilterValues;
                    break;
                case "payableFilter":
                    payableBtn = selectButton.text()
                    payableValues.push(jQuery(this).val());
                    myValues = payableValues;
                    break;
                case "deviceFilter[]":
                    devicesBtn = selectButton.text()
                    devicesValues.push(jQuery(this).val());
                    myValues = devicesValues;
                    break;
                case "statusFilter":
                    jStatusBtn = selectButton.text()
                    jStatusValues.push(jQuery(this).val());
                    myValues = jStatusValues;
                    break;
                default:
                    // do nothing
                    return;
            }
            selectButton.html(myValues.join(", "));
        } else if (jQuery(this).prop('checked', false)) {
            switch (jQuery(this).attr("name")) {
                case "typeJfilter[]":
                    myValues = removeFromArray(jobsTypeValues, jQuery(this).val());
                    selectButtonTxt = jobsTypeBtn;
                    break;
                case "payInFilter":
                    myValues = removeFromArray(payFilterValues, jQuery(this).val());
                    selectButtonTxt = payFilterBtn;
                    break;
                case "payableFilter":
                    myValues = removeFromArray(payableValues, jQuery(this).val());
                    selectButtonTxt = payableBtn;
                    break;
                case "deviceFilter[]":
                    myValues = removeFromArray(devicesValues, jQuery(this).val());
                    selectButtonTxt = devicesBtn;
                    break;
                case "statusFilter":
                    myValues = removeFromArray(jStatusValues, jQuery(this).val());
                    selectButtonTxt = jStatusBtn;
                    break;
                default:
                    // do nothing
                    return;
            }
            if (myValues.length == 0) {
                selectButton.html(selectButton.attr('title'));
            } else if (myValues.length <= 2) {
                selectButton.html(myValues.join(", "));
            }
        }
    }
    jQuery('#main-job-filter input[type="checkbox"]').change(filterDropdownSelector);

    /**** SHOW CHECKBOX TEXT INTO THE DROPDOWN BUTTON ****/
    function formatBytes(bytes, decimals = 3) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    jQuery(document).on('load turbolinks:load', function() {
        jQuery.each(jQuery('#main-job-filter input[type="checkbox"]'), filterDropdownSelector);
        if (jQuery(this).attr('spellchek') === 'true') {

        }
        jQuery('#text-editor').summernote({
            placeholder: jQuery('#text-editor').attr('placeholder'),
            lang: jQuery('html').attr('lang'),
            spellCheck: true,
            tabsize: 2,
            height: 200,
            minHeight: 200,
            maxHeight: 200,
            disableDragAndDrop: true,
            codeviewFilter: false,
            codeviewIframeFilter: true,
            toolbar: [
                // [groupName, [list of button]]
                ['font', ['fontname', 'fontsize']],
                ['style', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript']],
                ['clear', ['clear']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['view', ['fullscreen']]
            ],
            callbacks: {
                onInit: function() {
                    $('.dropdown-toggle').dropdown();
                }
            }
        });
        if (navigator.userAgent.indexOf("Firefox") > -1) {
            jQuery('.owl-carousel .share_jobs_options').css('background-color', 'rgba(var(--light-to-dark-opac-color),0.9)');
            jQuery('.share_jobs_options').css('background-color', 'rgba(var(--light-to-dark-opac-color),0.9)');
        }
    });


    /**** SHOW CHECKBOX TEXT INTO THE DROPDOWN BUTTON ****/
    jQuery('#generateScreenshot').on('click', function() {

        html2canvas(document.getElementById('myscreenshot'), {
            logging: true,
            profile: true,
            useCORS: true
        }).then(function(canvas) {
            var data = canvas.toDataURL();
            var src = encodeURI(data);
            jQuery('input[name="screenshotImg"]').val(src);
            jQuery('#supportScreenshot').css('background-image', 'url(' + src + ')');
            jQuery('#supportScreenshot').addClass('active');
            var screenshotSize = formatBytes(src.length);
        });
    });

    jQuery('#supportScreenshot').on('click', function() {
        jQuery('#supportScreenshot').removeAttr('style');
        jQuery('input[name="screenshotImg"]').removeAttr('value');
        jQuery('#supportScreenshot').removeClass('active');
    });

    /**** Handeling css peseudo classes for not required inputs ****/
    jQuery('input').focusout(function (){
        if ((!jQuery(this).prop('required'))&&(jQuery(this).val() != '')&&(!jQuery(this).hasClass('notempty'))) {
            jQuery(this).addClass('notempty');
        } else if (((jQuery(this).prop('type')=='email')||(jQuery(this).prop('type')=='password'))&&(jQuery(this).val() != '')&&(!jQuery(this).hasClass('notempty'))){
            jQuery(this).addClass('notempty');
        } else if ((jQuery(this).hasClass('notempty'))&&(jQuery(this).val() == '')){
            jQuery(this).removeClass('notempty');
        }
    });

    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        return new bootstrap.Popover(popoverTriggerEl, {
            trigger: 'manual',
            html: true,
            animation: false
        });
    });

    popoverTriggerList.forEach(function (popoverTriggerEl) {
        popoverTriggerEl.addEventListener('mouseenter', function () {
            var popover = bootstrap.Popover.getInstance(popoverTriggerEl);
            popover.show();
            var popoverEl = document.querySelector('.popover');
            if (popoverEl) {
                popoverEl.addEventListener('mouseleave', function () {
                    popover.hide();
                });
            }
        });

        popoverTriggerEl.addEventListener('mouseleave', function () {
            var popover = bootstrap.Popover.getInstance(popoverTriggerEl);
            setTimeout(function () {
                if (!document.querySelector('.popover:hover')) {
                    popover.hide();
                }
            }, 100);
        });
    });



    (function ($) {
        if ($('html').attr('lang') === 'de') {
            $.fn.selectpicker.defaults = {
                noneSelectedText: 'Bitte wählen...',
                noneResultsText: 'Keine Ergebnisse für {0}',
                countSelectedText: function countSelectedText(numSelected, numTotal) {
                    return numSelected == 1 ? '{0} Element ausgewählt' : '{0} Elemente ausgewählt';
                },
                maxOptionsText: function maxOptionsText(numAll, numGroup) {
                    return [numAll == 1 ? 'Limit erreicht ({n} Element max.)' : 'Limit erreicht ({n} Elemente max.)', numGroup == 1 ? 'Gruppen-Limit erreicht ({n} Element max.)' : 'Gruppen-Limit erreicht ({n} Elemente max.)'];
                },
                selectAllText: 'Alle',
                deselectAllText: 'Keine',
                multipleSeparator: ', '
            };
        } else if ($('html').attr('lang') === 'fr') {
            $.fn.selectpicker.defaults = {
                noneSelectedText: 'Rien n\'est sélectionné...',
                noneResultsText: 'Aucun résultat pour {0}',
                countSelectedText: function countSelectedText(numSelected, numTotal) {
                    return numSelected == 1 ? '{0} élément sélectionné' : '{0} éléments sélectionné';
                },
                maxOptionsText: function maxOptionsText(numAll, numGroup) {
                    return [numAll == 1 ? 'Limit erreicht ({n} Element max.)' : 'Limit erreicht ({n} Elemente max.)', numGroup == 1 ? 'Gruppen-Limit erreicht ({n} Element max.)' : 'Gruppen-Limit erreicht ({n} Elemente max.)'];
                },
                selectAllText: 'Toutes',
                deselectAllText: 'Aucune',
                multipleSeparator: ', '
            };
        } else {
            $.fn.selectpicker.defaults = {
                noneSelectedText: 'Nothing selected',
                noneResultsText: 'No results match {0}',
                countSelectedText: function countSelectedText(numSelected, numTotal) {
                    return numSelected == 1 ? '{0} item selected' : '{0} items selected';
                },
                maxOptionsText: function maxOptionsText(numAll, numGroup) {
                    return [numAll == 1 ? 'Limit reached ({n} item max)' : 'Limit reached ({n} items max)', numGroup == 1 ? 'Group limit reached ({n} item max)' : 'Group limit reached ({n} items max)'];
                },
                selectAllText: 'Select All',
                deselectAllText: 'Deselect All',
                multipleSeparator: ', '
            };
        }
    })(jQuery);

    jQuery('.invalid-feedback').each(function() {
        jQuery(this).insertAfter(jQuery(this).parent().find('.bar'));
    });

    document.querySelectorAll('.toast').forEach(toastEl => {
        const toast = new bootstrap.Toast(toastEl, {
            autohide: false
        });
        toast.show();
    });

    let specificToast = document.getElementById('toast');
    if (specificToast) {
        const toastInstance = new bootstrap.Toast(specificToast);
        toastInstance.show();
    }

    document.querySelectorAll('.modal').forEach(modalEl => {
        const modalInstance = bootstrap.Modal.getInstance(modalEl) || new bootstrap.Modal(modalEl);
        modalInstance.handleUpdate();
    });

    let specificModal = document.getElementById('modal');
    if (specificModal) {
        const modalInstance = new bootstrap.Modal(specificModal);
        modalInstance.show();
    }


    if ($('.load-clickworker-balances').length) {
        $.ajax({
            url: document.location.origin + '/' + I18n.locale + '/workplace/users/balances',
            type: 'GET',
            dataType: 'json',
            success: (data) => {
                const balanceContainer = $('.load-clickworker-balances')[0];
                if (balanceContainer) {
                    let balanceSpan = balanceContainer.querySelector('.clickworker-balance span');
                    let payableSpan = balanceContainer.querySelector('.clickworker-balance-payable span');
                    if (balanceSpan) balanceSpan.textContent = data.balance;
                    if (payableSpan) payableSpan.textContent = data.payable;
                    setTimeout(() => {
                        balanceContainer.classList.remove('load-clickworker-balances');
                        balanceContainer.querySelectorAll('.placeholder-glow').forEach(el => el.classList.remove('placeholder-glow'));
                        balanceContainer.querySelectorAll('.placeholder').forEach(el => el.classList.remove('placeholder'));
                    }, 3000);
                }
            }
        })
    }

    jQuery('.logout').on('click',function(){
        localStorage.setItem("last_CWWtheme_setting",jQuery('html').attr('data-bs-theme'));
        localStorage.setItem("last_cwwAccessibility_setting",jQuery('html').attr('data-accessibility'));
    });

    jQuery('#disable-account').on('click',function(){
        if (localStorage.getItem('last_CWWtheme_setting')) localStorage.removeItem('last_CWWtheme_setting');
        if (localStorage.getItem('last_cwwAccessibility_setting')) localStorage.removeItem('last_cwwAccessibility_setting');
        if (localStorage.getItem(gon.user+'_CWWtheme')) localStorage.removeItem(gon.user + '_CWWtheme');
        if (localStorage.getItem(gon.user+'_cwwAccessibility')) localStorage.removeItem(gon.user + '_cwwAccessibility');
        if (localStorage.getItem(gon.user+'_sideNavState')) localStorage.removeItem(gon.user + '_sideNavState');
        if (localStorage.getItem(gon.user+'_CWWProfileBackground')) localStorage.removeItem(gon.user + '_CWWProfileBackground');
    });

   jQuery('input.input_vor_training').on('keydown', function(){
       var inputLength = jQuery(this).val().length == 0 ? 1 : jQuery(this).val().length;
       jQuery(this).attr('size', inputLength * 1.25 ).attr('style','width:fit-content !important;');
   });
   jQuery('input.input_vor_training').on('keyup', function(){
       if ((jQuery(this).val()=='') || (jQuery(this).val().length == 0)) {
           jQuery(this).removeAttr('size').removeAttr('style');
       }
   });

    if ($('[id*="workplace_jobs_"] .card .card-header #jobsInfoHeader').length > 0 || $('[id*="admin_jobs_"] .card .card-header #jobsInfoHeader').length > 0) {
        jQuery(window).on('scroll', function() {
            var scroll = jQuery(window).scrollTop();
            if (scroll >= 150) {
                jQuery("#jobsInfoHeader").addClass('fixedToTop');
            } else {
                jQuery("#jobsInfoHeader").removeClass('fixedToTop');
            }
        });
    }

    // Shuffle cancel job list
    if ($('#cancel_reason_list').length) {
        shuffleElements($('.shuffle button.to-shuffle'));
    }
    function shuffleElements($elements) {
        var i, index1, index2, temp_val;

        var count = $elements.length;
        var $parent = $elements.parent();
        var shuffled_array = [];


        // populate array of indexes
        for (i = 0; i < count; i++) {
            shuffled_array.push(i);
        }

        // shuffle indexes
        for (i = 0; i < count; i++) {
            index1 = (Math.random() * count) | 0;
            index2 = (Math.random() * count) | 0;

            temp_val = shuffled_array[index1];
            shuffled_array[index1] = shuffled_array[index2];
            shuffled_array[index2] = temp_val;
        }

        // apply random order to elements
        $elements.detach();
        for (i = 0; i < count; i++) {
            $parent.append( $elements.eq(shuffled_array[i]) );
        }
    }

    document.addEventListener('pgJobOverlayEvent', function() {
        jQuery('#pg_job_overlay').modal('show');
    });

    document.querySelectorAll('.cancel-job-reason[data-confirm]').forEach(function (element) {
        element.addEventListener('click', function (event) {
            event.preventDefault();
            event.stopPropagation();
            var message = this.getAttribute('data-confirm');
            var target = this.getAttribute('data-bs-target');

            if (confirm(message)) {
                var modal = document.querySelector(target);
                if (modal) {
                    var modalInstance = new bootstrap.Modal(modal);
                    modalInstance.show();
                }
            }
        });
    });
});


