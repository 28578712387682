$(document).on('load turbolinks:load', function() {
    // Initialize Smart Wizard
    $('#rootwizard').smartWizard({
        theme: 'arrows',
        autoAdjustHeight: true,
        toolbarSettings: {
            showNextButton: false,
            showPreviousButton: false,
            toolbarExtraButtons: [
                $('<button></button>').text('Finish').addClass('btn btn-info finish')
                    .on('click', function() {
                        $('#success-modal').modal();
                    })
            ]
        },
        transition: {
            animation: 'fade',
            speed: '400'
        }
    });

    $('#rootwizard').on("showStep", function(e, anchorObject, stepNumber, stepDirection, stepPosition) {
        var totalSteps = anchorObject.closest('ul').find('li').length;
        var currentStep = stepNumber + 1;
        var percent = (currentStep / totalSteps) * 100;
        $('#rootwizard .progressbar').css({
            width: percent + '%'
        });
    });

    /******** Tabpane helper for login page ********/
    jQuery('.loginToggler').click(function(e) {
        jQuery('.loginToggler').removeClass('active');
    });

    // Custom Checkbox
    jQuery('.checkbox').click(function() {
        jQuery(this).toggleClass('is-checked');
    });

    // Check / Uncheck all checkboxes
    jQuery("#check-all").change(function() {
        jQuery("input:checkbox").prop('checked', jQuery(this).prop("checked"));
    });
});
