$(document).on('assigned-jobs-loaded', function() {
    var closeShorties = document.getElementById('close-shorties');
    $('.shorties_wrp.not-active .shorties_wrp--cards').click(function(){
        if  ($('.shorties_wrp').hasClass('not-active')){
            var posH = $(window).width()/2 - 390 + 'px';
            var posV = $(window).height()/2 - 505 + 'px';
            $('.shorties_wrp').animate({transform: 'translate('+posH+','+posV+')'});
            $('.shorties_wrp.not-active').toggleClass('active').toggleClass('card').toggleClass('not-active');
            $('body').attr('style', 'overflow:hidden;position:fixed;width:100%;');
        }

        var shorties_wrpContainer = document.querySelector('.shorties_wrp.active');
        var allCards = document.querySelectorAll('.shorties_card');
        var nextShorty = document.getElementById('next-shorty');
        var dislikeOptions = document.getElementsByClassName('like_disliked_checkbox');
        var shortiesTabToggle = document.getElementsByClassName('shorties-tab-toggle');
        var b = document.getElementById("workplace_jobs_index");

        function initCards(card, index) {
            var newCards = document.querySelectorAll('.card.shorties_card:not(.removed)');
            newCards.forEach(function (card, index) {
                card.style.zIndex = allCards.length - index;
                card.style.transform = 'scale(' + (20 - index) / 20 + ') translateY(' + 20 * index + 'px)';
                card.style.opacity = (10 - index) / 10;
            });

            setTimeout(function() {
                jQuery('.shorties_card.removed').next('.shorties_card').toggleClass('current');
                jQuery('.shorties_card.removed').remove();

                if (jQuery('.shorties_card').length == 0){
                    jQuery('.shorties_list_container').remove();
                    jQuery('#shorties_wrp--buttons').addClass('finish_shorties');
                    jQuery('#shorties_wrp--buttons button#next-shorty').removeAttr('id').addClass('btn-outline-primary finish').text(I18n.t('shorties_finish.finish_btn_text')).click(finishShorties);
                    jQuery('#close-shorties').addClass('finish').click(finishShorties);
                    jQuery('#shorties_finish_congrats h1').length == 0 ? jQuery('#shorties_finish_congrats').removeClass('d-none').append('<div class="congrats-icon"></div><h1>' + I18n.t("shorties_finish.title") + '</h1><p>' + I18n.t("shorties_finish.first_text") + '<br>' + I18n.t("shorties_finish.second_text") + '</p>') : '';
                    // jQuery('.add_new_shortie').length == 0 ? jQuery('#shorties_wrp--buttons').append('<a href="/shorties/new" class="button btn-primary add_new_shortie">' + I18n.t("shorties_finish.creat_new_btn_text") + '</a>') : '';
                }

            }, 100);
            shorties_wrpContainer.classList.add('loaded');
        }

        initCards();

        allCards.forEach(function (el) {
            var hammertime = new Hammer(el);

            hammertime.on('pan', function (event) {
                el.classList.add('moving');
            });

            hammertime.on('pan', function (event) {
                if (event.deltaX === 0) return;
                if (event.center.x === 0 && event.center.y === 0) return;
                event.target.closest('.shorties_card').style.transform = 'translate(' + event.deltaX + 'px, ' + event.deltaY + 'px)';
            });

            hammertime.on('panend', function (event) {
                el.classList.remove('moving');
                var moveOutWidth = document.body.clientWidth;
                var keep = Math.abs(event.deltaX) < 80 || Math.abs(event.velocityX) < 0.5;

                event.target.closest('.shorties_card').classList.toggle('current', keep);
                event.target.closest('.shorties_card').classList.toggle('removed', !keep);
                if (keep) {
                    event.target.style.transform = '';
                } else {
                    var endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth);
                    var toX = event.deltaX > 0 ? endX : -endX;
                    var endY = Math.abs(event.velocityY) * moveOutWidth;
                    var toY = event.deltaY > 0 ? endY : -endY;
                    event.target.closest('.shorties_card').style.transform = 'translate(' + toX + 'px, ' + (toY + event.deltaY) + 'px)';
                    initCards();
                    event.target.closest('.shorties_card').classList.toggle('current');
                }
            });
            hammertime.get('pan').set({ enable: false });


        });

        if ((jQuery('.shorties_wrp').hasClass('active')) && (jQuery('.shorties_card').length > 0)){
            b.addEventListener("keydown", (evt) => {
                if (($('.shorties_wrp').hasClass('active')) && (evt.keyCode == 37 || evt.keyCode == 39)){
                    $('#next-shorty').trigger( "click" );
                    evt.preventDefault();
                }
            });
        }

        nextShorty.addEventListener('click', nextButton());

        function nextButton() {
            return function (event) {
                var cards = document.querySelectorAll('.shorties_card:not(.removed):first-child');
                var moveOutWidth = document.body.clientWidth * 1.5;
                if (!cards.length) return false;
                var card = cards[0];
                Rails.fire($(card).find('form.js-shorty')[0], 'submit');
                card.classList.add('removed');
                card.style.transform = 'translate(-' + moveOutWidth + 'px, -100px) rotate(30deg)';
                if (document.querySelectorAll('.shorties_card:not(.removed)').length < 20) {
                    loadShorties();
                }
                initCards();
                event.preventDefault();
            };
        };

        function loadShorties() {
            $.ajax({
                url: '/' + I18n.locale + '/workplace/shorties_answers',
                method: 'GET',
                success: function(result) {
                    if (result) {
                        let newResults = false;
                        $(result).each(function() {
                            $(this).removeClass('active');
                            if ($('#' + $(this).attr('id')).length == 0 && $(this).attr('id')) {
                                if (!newResults) {
                                    newResults = $('<div>')
                                }
                                newResults.append($(this.outerHTML))
                            }
                        })
                        if (newResults) {
                            let preparedResults = $(newResults.html())
                            initShortiesContainer(preparedResults)
                            $('.shorties_list_container').append(preparedResults)
                        }
                    }
                }
            })
        }

        shortiesTabToggle.forEach(function(tabToggleButton) {
            tabToggleButton.addEventListener("click", function() {
                if ( $(tabToggleButton).hasClass('.active') || $(tabToggleButton).attr('aria-selected') == 'true') {
                    setTimeout(function() {
                        tabToggleButton.removeAttribute('aria-selected');
                        tabToggleButton.classList.remove('active');
                        $('.shorties_card.current .tab-pane').removeClass('active show');
                        $('.shorties_card.current .tab-content .tab-pane.main-tab-pane').addClass('active show');
                    }, 100);
                }
            });
        });

        $('input.like_disliked_checkbox').on("change", function() {
            $('label').removeClass('checked');
            $('.dropdown-toggle').removeClass('checked');
            if ($(this).parents().closest('.dropdown-menu')) {
                var dropdown = $(this).parents().closest('.dropdown-menu');
                var radioname = dropdown.find('.dropdown-radio').attr('id');
                $('label').removeClass('checked');
                if ($(this).parents('.dropdown-menu').length) {
                    $(this).closest('label').addClass('checked');
                    $(this).parents().closest('.dropdown-menu').addClass('checked');
                    $(this).parents().closest('.dropdown-menu').prev().closest('.dropdown-toggle').addClass('checked');
                }
            }
        });

        // $('.selectpicker').selectpicker(); ???

    });

    if (closeShorties) {
        var allCards = document.querySelectorAll('.shorties_card');
        closeShorties.addEventListener('click', function () {
            if ($('.shorties_wrp .shorties_card').length > 0) {
                setTimeout(function () {
                    $('.shorties_wrp').removeClass('loaded').removeClass('active').addClass('card').addClass('not-active');
                    allCards.forEach(function (card) {
                        const backgroundGradient = card.style.background;
                        card.removeAttribute('style');
                        card.style.background = backgroundGradient;
                    });
                }, 1);
            }
            $('body').removeAttr('style');
        });
    }

    function finishShorties(){
        if ($('.shorties_wrp .shorties_card').length == 0){
            setTimeout(function() {
                $('.shorties_wrp').remove();
            }, 105);
        }
        $('body').removeAttr('style');
    }

    function showResults(form) {
        let shortyAnswerGrid = form.find('.shorties_grid').data('grid-column');
        let shortyColor = form.find('.shorties_grid').data('shorty-color');
        const resultsTemplate = '<div class="shorties_grid_container js-results">'
            + '<div id="shorty_results" class="shorties_grid column-'+ shortyAnswerGrid +'">'
                + '<div class="row align-items-center result-row">'
                    + '<div class="col">'
                        + '<div class="styled-results">'
                            + '<div class="results-span" style="color: '+ shortyColor +';">'
                                + '<span class="darken-layer"></span>'
                                + '<span style="color: '+ shortyColor +';"></span>'
                                + '<div class="bar-graph"></div>'
                            + '</div>'
                        + '</div>'
                    + '</div>'
                    + '<div class="col-auto col-results-percent-width ps-0">'
                        + '<p class="js-result-percent text-end"></p>'
                    + '</div>'
                + '</div>'
            + '</div>'
        + '</div>';
        form.find('.shorties_grid_container').hide();
        form.find('.js-results').remove();
        const defaultFilter = 'all';
        let resultContainer = $(resultsTemplate);
        let resultRow = resultContainer.find('.align-items-center').clone();
        resultContainer.find('.align-items-center').remove();
        $.each(form.parents('.shorties_card').data('responses')[defaultFilter], function(key, value) {
            let newResultRow = resultRow.clone();
            newResultRow.find('.results-span span').append(key);
            newResultRow.find('.results-span .bar-graph').css('width', parseInt(value * 100) + '%');
            newResultRow.find('.js-result-percent').append(parseInt(value * 100)     + '%');
            resultContainer.find('.shorties_grid').append(newResultRow);
        });

        form.find('.shorties_grid_container').parent().after(resultContainer)

        let myChoicesIndex = [];
        form.find('input[id*="choice_"]').each(function() {
            if ($(this).is(':checked')) {
                myChoicesIndex.push($(this).data('choice-nb'));
            }
        });
        $.each(myChoicesIndex, function (index,value){
            form.find('.result-row:nth-of-type('+ (value + 1) +') .results-span').addClass('my-choice');
        });
    }

    function initShortiesContainer(container) {
        container.each(function() {
            $(this).find('.btn-shorty-submit').click(function() {
                showResults($(this).parents('form.js-shorty'));
            });
            $(this).find('.shorties_grid input[type=radio]').change(function() {
                $(this).parents('form.js-shorty').find('.shorties_grid input[type=radio]:checked').not(this).prop('checked', false);
                showResults($(this).parents('form.js-shorty'));
            });
            $(this).find('.like_disliked_checkbox').change(function() {
                if ($(this).data('reason')) {
                    $(this).parents('form.js-shorty').find('input.js-reason').removeAttr('disabled').val($(this).data('reason'))
                } else {
                    $(this).parents('form.js-shorty').find('input.js-reason').attr('disabled', 'disabled')
                }
            });
            $(this).find('.shorties_grid input[type=checkbox]').change(function() {
                if ($(this).parents('form.js-shorty').find('.shorties_grid input[type=checkbox]:checked').length > 0) {
                    $(this).parents('form.js-shorty').find('.btn-shorty-submit').show();
                } else {
                    $(this).parents('form.js-shorty').find('.btn-shorty-submit').hide();
                }
            });
        })
    }

    initShortiesContainer($('form.js-shorty'))
});
