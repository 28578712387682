import * as jQuery from 'jquery';
import '@popperjs/core'
import moment from 'moment'
import Noty from 'noty'
import 'jquery.nicescroll'
import 'jszip'
import 'pdfmake'
import 'datatables.net-bs4/js/dataTables.bootstrap4.min'
import 'datatables.net-buttons-bs4/js/buttons.bootstrap4.min'
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4.min'
import 'owl.carousel'
import $ from 'jquery/src/jquery'
window.$ = window.jquery = window.jQuery = $;
import 'summernote/dist/summernote-bs5.min'
import selectpicker from 'bootstrap-select'
import 'uuid'
import I18n from 'i18n-js'
import 'smartwizard'
import Porthole from 'porthole-proxy'
import 'jquery.flot.current'
import 'jQuery.print'
import 'hammerjs'
import 'jquery-ui/ui/widgets/sortable'; /* For the ranking df element */
import 'jquery-ui/ui/disable-selection'; /* For the ranking df element */

window.Noty = Noty;
window.moment = moment;
window.I18n = I18n;
window.Porthole = Porthole;
window.Noty = Noty
window.selectpicker = selectpicker;
window.spicker = selectpicker;

//needed for df/fb validation
function initSelectPicker(selector) {$(selector).selectpicker('refresh');}
function destroySelectPicker(selector) {$(selector).selectpicker('destroy');}
function refreshSelectPicker(selector) {$(selector).selectpicker();}
window.refreshSelectPicker = refreshSelectPicker;
window.destroySelectPicker = destroySelectPicker;
window.initSelectPicker = initSelectPicker;
