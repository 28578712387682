$(document).on 'ready turbolinks:load', ->
  if $('#workplace_users_settings').length > 0
    window.profilePage = new ProfilePage($('#workplace_users_settings'))
    window.profilePage.init()

class ProfilePage extends window.EventedClass
  constructor: (root) ->
    super()
    @root = root

  init: ->
    portholeUrl = document.getElementById('profileFrame').dataset.portholeUrl
    windowProxy = new Porthole.WindowProxy(portholeUrl, 'profileFrame')
    windowProxy.addEventListener(@onMessage)

  onMessage: (messageEvent) ->
    if (messageEvent.data == 'avatar_changed')
      window.profileAvatar.reloadAvatar()
    else
      jQuery('#profileFrame').css('height', messageEvent.data.height + 100 + 'px')
