import pattern1 from '../../../images/workplace/patterns/1.png'
import pattern2 from '../../../images/workplace/patterns/2.png'
import pattern3 from '../../../images/workplace/patterns/3.png'
import pattern4 from '../../../images/workplace/patterns/4.png'
import pattern5 from '../../../images/workplace/patterns/5.png'
import pattern6 from '../../../images/workplace/patterns/6.png'
import pattern7 from '../../../images/workplace/patterns/7.png'
import pattern8 from '../../../images/workplace/patterns/8.png'
import pattern9 from '../../../images/workplace/patterns/9.png'
import pattern10 from '../../../images/workplace/patterns/10.png'
import pattern11 from '../../../images/workplace/patterns/11.png'
jQuery(document).on('load turbolinks:load', function() {
    'use strict';

    // ------------------------------------------------------- //
    // Preloader Orders
    // ------------------------------------------------------ //

    jQuery(window).on("load turbolinks:load", function() {
        jQuery(".loader").fadeOut();
        jQuery("#preloader").delay(150).fadeOut("slow");
    });

    // ------------------------------------------------------- //
    // to Previous-Page
    // ------------------------------------------------------ //
    jQuery('#previous-page').on('click', function(event) {
        event.preventDefault();
        history.back(1);
    });

    // ------------------------------------------------------- //
    // Login Show Passwords
    // ------------------------------------------------------ //
    jQuery('[id="showPassword"]').each(function() {
        jQuery(this).on('click', function () {
            jQuery(this).toggleClass('fa-eye-slash fa-eye');
            var input = jQuery(jQuery(this).attr('toggle'));
            if (input.attr('type') === 'password') {
                input.attr('type', 'text');
            } else {
                input.attr('type', 'password');
            }
        })
    });


    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl);
    });



    // ------------------------------------------------------- //
    // avoid reload page vor href="#"
    // ------------------------------------------------------ //
    jQuery('[href="#"]').on('click', function(event) {
        event.preventDefault();
    });

    // ------------------------------------------------------- //
    // Owl Carousel Settings
    // ------------------------------------------------------ //

    /************* STORIES SLIDER **************/
    let owl = jQuery('.stories-slider');
    let owl_navs = jQuery('.stories-slider .owl-nav');
    let owl_dots = jQuery('.stories-slider .owl-dots');
    let owl_cloned = jQuery('.stories-slider .owl-stage-outer .owl-stage .cloned');

    if(owl.hasClass('owl-loaded')) {
        owl_navs.remove();
        owl_dots.remove();
        owl_cloned.remove();
    }

    owl.owlCarousel({
        loop: false,
        margin: 30,
        autoplay: false,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: false,
                touchDrag: true,
                mouseDrag: true,
                dots: true
            },
            992: {
                items: 2,
                nav: true,
                mouseDrag: true,
                dots: true
            },
            1200: {
                items: 3,
                nav: true,
                mouseDrag: true,
                dots: true
            }
        }
    });
    if (localStorage.getItem(gon.user+'_sideNavState')!='' && ((localStorage.getItem(gon.user+'_sideNavState')=='minimized') || (localStorage.getItem(gon.user+'_sideNavState')=='notMinimized'))){
        setTimeout(function(){
            updateOwl();
        },   321);
    }
    jQuery('#lock-navbar').on('click', function(e) {
        setTimeout(function(){
            updateOwl();
        },   321);
    });

    /************* MORE JOBS SLIDER **************/
    jQuery('.jobs-slider').owlCarousel({
        loop: true,
        margin: 10,
        autoplay: false,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: false,
                loop: true,
                touchDrag: true,
                mouseDrag: true,
                dots: true
            },
            1000: {
                items: 3,
                nav: true,
                loop: true,
                touchDrag: true,
                mouseDrag: true,
                dots: true
            },
            1600: {
                items: 4,
                nav: true,
                loop: true,
                mouseDrag: true,
                dots: true
            }
        }
    });


    /************* JOBS DETAILS MORE JOBS SLIDER **************/
    jQuery('.jobs-details-slider').owlCarousel({
        loop: false,
        margin: 10,
        autoplay: false,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: false,
                touchDrag: true,
                mouseDrag: true,
                dots: true
            },
            1000: {
                items: 4,
                nav: true,
                touchDrag: true,
                mouseDrag: true,
                dots: true
            },
            1600: {
                items: 4,
                nav: true,
                mouseDrag: true,
                dots: true
            }
        }
    });


    /************* STORIES SLIDER **************/
    jQuery('.jlist-jobs-slider').not('.listView').owlCarousel({
        margin: 10,
        loop: true,
        nav: false,
        dots: true,
        autoplay: false,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                touchDrag: true,
                mouseDrag: true
            },
            768: {
                items: 2,
                mouseDrag: true
            },
            992: {
                items: 3,
                mouseDrag: true
            }
        }
    });

    // ------------------------------------------------------- //
    // Sidebar Functionality
    // ------------------------------------------------------ //
    /*
    */

    if (window.matchMedia('(max-width: 991px)').matches) {
        jQuery('.default-sidebar #toggle-btn').on('click', function(e) {
            e.preventDefault();
            jQuery(this).toggleClass('notactive');
            jQuery('.side-navbar').toggleClass('shrinked');
        });
    };

    if (window.matchMedia('(min-width: 992px)').matches) {
        jQuery('#lock-navbar').on('click', function (e) {
            e.preventDefault();
            jQuery(this).parent().toggleClass('locked');
            jQuery(this).toggleClass('active');
            if (jQuery('.side-navbar').hasClass('locked')) {
                jQuery('nav.side-navbar').removeClass('locked');
                jQuery('.navbar-brand').addClass('shrinked');
                localStorage.getItem(gon.user + '_sideNavState') == 'maximized' ? jQuery('.content-inner').addClass('active') : '';
                localStorage.removeItem(gon.user + '_sideNavState');
            } else {
                jQuery('.side-navbar').addClass('locked');
                if (jQuery('.side-navbar').hasClass('shrinked')) {
                    localStorage.setItem(gon.user + '_sideNavState', 'minimized');
                } else if (jQuery('.side-navbar').hasClass('mouseWasOvered')) {
                    localStorage.setItem(gon.user + '_sideNavState', 'maximized');
                    if (!jQuery('.side-navbar').hasClass('mouseWasOvered')) {
                        jQuery('.side-navbar').addClass(["mouseWasOvered"]);
                    } else {
                        jQuery('.navbar-brand').removeClass('shrinked');
                        jQuery('.content-inner').removeClass('active');
                    }
                }
            }
        });

        jQuery('body:not(#sessions_new)').on('mouseenter', '.side-navbar.shrinked:not(.locked)', function (e) {
            jQuery(this).hasClass('shrinked') ? jQuery(this).removeClass('shrinked').addClass('mouseWasOvered') : '';
            jQuery('#toggle-btn').removeClass('notactive').appendTo('.side-navbar');
        });

        jQuery('body:not(#sessions_new)').on('mouseleave', '.side-navbar.mouseWasOvered:not(.locked)', function (e) {
            jQuery('#toggle-btn').appendTo('.default-sidebar');
            jQuery(this).hasClass('mouseWasOvered') ? jQuery(this).removeClass('mouseWasOvered').addClass('shrinked') : '';
            !jQuery('#toggle-btn').hasClass('notactive') ? jQuery('#toggle-btn').addClass('notactive') : '';
        });
    }

        // Close dropdown after click
        jQuery(function() {
            jQuery(".side-navbar li a").click(function(event) {
                jQuery(".side-navbar .collapse").collapse('hide');
            });
        });


    // Close navbar after click on link
    jQuery('side-navbar:not(.locked) .sidebar-main-content .list-unstyled li a:not([data-bs-toggle],[data-bs-theme],[data-access-toggler])').on('click', function(){
        if (jQuery('nav.side-navbar').hasClass('mouseWasOvered')) {
            jQuery('nav.side-navbar').removeClass('mouseWasOvered');
            jQuery('nav.side-navbar').addClass('shrinked');
        }
    });

    // ------------------------------------------------------- //
    // Sidebar Scrollbar
    // ------------------------------------------------------ //
    jQuery(".sidebar-scroll:not(.register-login-menu)").niceScroll({
        cursorcolor: "transparent",
        cursorborder: "transparent",
        boxzoom: false,
        autohidemode: "hidden"
    });


    // ------------------------------------------------------- //
    // Dynamic Height
    // ------------------------------------------------------ //
    jQuery(window).resize(function() {
        var height = jQuery(this).height() - jQuery(".header").height() + jQuery(".main-footer").height()
        jQuery('.d-scroll').height(height);
    });
    jQuery(window).resize();


    // ------------------------------------------------------- //
    // Return Button
    // ------------------------------------------------------ //
    jQuery('#previous-page').on('click', function(e) {
        window.history.back();
    });


    // ------------------------------------------------------- //
    // on Scroll to top show top Nav
    // ------------------------------------------------------ //
    var lastScrollTop = 0;
    jQuery(window).scroll(function(event) {
        var st = jQuery(this).scrollTop();
        if (st > lastScrollTop) {
            // downscroll code
        } else {
            // upscroll code
        }
        lastScrollTop = st;
    });


    // ------------------------------------------------------- //
    // Change Background for user settings
    // ------------------------------------------------------ //

    jQuery('#bgmenu').click(function(e) {
        e.preventDefault();
        jQuery('.background-selector').toggleClass('active');
    });

    jQuery(".bg-select li").on("click", function() {
        // Remove class from currently active button
        jQuery(".bg-select > li").removeClass("active");

        // Add class active to clicked button
        jQuery(this).addClass("active");

        // Get background of clicked
        var newBackground = jQuery(this).attr("data-background");
        localStorage.setItem(gon.user+'_CWWProfileBackground', newBackground);
        var patterns = { '1': pattern1, '2': pattern2, '3': pattern3, '4': pattern4, '5': pattern5, '6': pattern6, '7': pattern7, '8': pattern8, '9': pattern9, '10': pattern10, '11': pattern11,}
        var profiBg = "url(" + patterns[newBackground] + ")"
        // Change background of everything with class .bg-color
        jQuery("#profile-cover-bg").css("background-image", profiBg);
    });


    // ------------------------------------------------------- //
    // Clickworker Profile edit toggler
    // ------------------------------------------------------ //
    jQuery('[id^="edit-entry"]').on('click', function() {
        // Remove class from currently active button
        jQuery(this).parent().toggleClass('edit-active');
        if ((jQuery(this).closest(".form-group").find("[disabled]").length) || (jQuery(this).closest(".form-group").find(".disabled").length)) {
            jQuery(this).closest('.form-group').find('[disabled]').prop('disabled', false).addClass('activatedForEdit');
            jQuery(this).closest('.form-group').find('.disabled').removeClass('disabled').addClass('activatedForEdit');
            jQuery(this).closest('.form-group').find('[aria-disabled="true"]').attr('aria-disabled', false).addClass('activatedForEdit');
        }
    });
    jQuery('[id^="discard-entry-changes"]').on('click', function() {
        jQuery(this).parent().toggleClass('edit-active');
        jQuery(this).closest('.form-group').find('.activatedForEdit').attr('disabled', 'disabled');
        jQuery(this).closest('.form-group').find('.activatedForEdit').removeClass('activatedForEdit');
    });

    // ------------------------------------------------------- //
    // Options hover effect to dropdown
    // ------------------------------------------------------ //
    jQuery('.widget-options > .dropdown, .actions > .dropdown, .quick-actions > .dropdown').hover(function() {
        jQuery(this).find('.dropdown-menu').stop(true, true).delay(100).fadeIn(350);
    }, function() {
        jQuery(this).find('.dropdown-menu').stop(true, true).delay(100).fadeOut(350);
    });

    var updateOwl = function(){
        $(".owl-carousel").each(function() {
            $(this).data('owl.carousel').onResize();
        });
    };

    jQuery(function(){
        jQuery('.invalid-feedback.bs-select').each(function() {
            jQuery(this).appendTo(jQuery(this).closest('.form-group').find('.bootstrap-select'));
        });
    });

    jQuery('#shareWithCopy').on('click', function (){
        var value_to_copy = jQuery(this).data('valtocopy');
        navigator.clipboard.writeText(value_to_copy).then(function() {
            var msg = I18n.t('copy_tooltip.succeed');
            var msg_class = 'text-success';
            showCopyFeedback(msg, msg_class);
        }, function() {
            var msg = I18n.t('copy_tooltip.failed');
            var msg_class = 'text-danger';
            showCopyFeedback(msg, msg_class);
        });
    });
    function showCopyFeedback(msg, msg_class) {
        $('#copyAnswerTxt').addClass(msg_class).html(msg).fadeIn(200).delay(400).fadeOut(200, function() {
            $(this).removeClass(msg_class);
        });
    }
});
