$(document).on('turbolinks:load', function() {
    $(window).trigger('load.bs.select.data-api');
    $('.selectpicker').selectpicker();
});

$(document).on('turbolinks:before-cache', function() {
    var $picker = $('.selectpicker');
    if ($picker.length > 1) {
        $picker.each(function(i) {
            var picker = $(this).data('selectpicker');
            if (picker) {
                picker.destroy();
                $(this).addClass('selectpicker');
            }
        })
    } else {
        var picker = $picker.data('selectpicker');
        if (picker) {
            picker.destroy();
            $picker.addClass('selectpicker');
        }
    }
});
