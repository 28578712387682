$(document).on('load turbolinks:load', function() {
    moment.locale(I18n.locale);
    const daterangeElement = $('input[name="daterange"], #daterange');
    const dateFormat = I18n.t('date.formats.daterangepicker')

    daterangeElement.daterangepicker({
        locale: {
            format: dateFormat,
            cancelLabel: I18n.t('daterangepicker.clear'),
            applyLabel: I18n.t('daterangepicker.apply'),
            customRangeLabel: I18n.t('daterangepicker.custom_range')
        },
        ranges: {
            [I18n.t('daterangepicker.today')]: [moment(), moment()],
            [I18n.t('daterangepicker.yesterday')]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            [I18n.t('daterangepicker.last_7_days')]: [moment().subtract(6, 'days'), moment()],
            [I18n.t('daterangepicker.last_30_Days')]: [moment().subtract(29, 'days'), moment()],
            [I18n.t('daterangepicker.this_month')]: [moment().startOf('month'), moment().endOf('month')],
            [I18n.t('daterangepicker.last_month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        alwaysShowCalendars: false,
        showDropdowns: true,
        startDate: daterangeElement.data('startDate') || moment().subtract(29, 'days'),
        endDate: daterangeElement.data('endDate') || moment(),
        maxDate: moment(),
        opens: 'center',
        cancelButtonClasses: 'cancelBtn btn btn-sm btn-outline-primary'

    }, function(start, end, label) {
        console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
    });
    daterangeElement.on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
        $(this).hasClass('notempty') ? $(this).val('') : '';
    });
    $('#daterange').on('apply.daterangepicker', function(ev, picker) {
        !($(this).hasClass('notempty')) ? $(this).addClass('notempty') : '';
    });
    if (daterangeElement.length > 0 && !daterangeElement.data('autoUpdateInput')) {
        $('input[name="daterange"], #daterange').on('apply.daterangepicker', function (ev, picker) {
            $(this).val(picker.startDate.format(dateFormat) + ' - ' + picker.endDate.format(dateFormat));
        });

        var startDate = daterangeElement.data('daterangepicker').element[0].dataset.startDate;
        var endDate = daterangeElement.data('daterangepicker').element[0].dataset.endDate;
        if (startDate && endDate) {
            daterangeElement.val(startDate + ' - ' + endDate);
        }
    }
});
