$(document).on 'ready turbolinks:load', ->
  if $('.phone-verification-btn').length > 0
    window.phoneVerificationPage = new PhoneVerificationPage()
    window.phoneVerificationPage.init()

class PhoneVerificationPage extends window.EventedClass
  constructor: ->
    super()
    @verificationButton = $('.phone-verification-btn')
    @verificationModal = $('#phoneVerificationModal')
    @initialized = false

  init: ->
    if @verificationButton.length
      @verificationButton.on 'click', ()=>
        if !@initialized
          window.RingCaptchaLocale = {
            de: {
              'check.help': 'Hast du die falsche Nummer eingetragen oder brauchst du einen neuen Code?',
              'check.countdown.minutes': 'Versuch es erneut in %remaining% minute(n).|Versuch es erneut in %remaining% minute(n).',
              'check.countdown.seconds': 'Versuch es erneut in %remaining% Sekunde(n).|Versuch es erneut in %remaining% Sekunde(n).',
              'check.retry': 'Erneut versuchen',
              'check.fallback': 'Sprachanruf',
              'check.error.invalid_pin_code': 'Ungültiger PIN-Code',
              'check.fallback': 'Ruf mich bitte jetzt an!',
              'check.help': 'Hast du die falsche Nummer eingegeben oder brauchst du einen neuen Code?',
              'check.success': 'Danke! Deine Telefonnummer wurde verifiziert!',
              'check.summary': 'Gebe den vierstelligen Pin-Code zur Überprüfung ein.',
              'check.title.sms': 'SMS mit dem PIN-Code zu:',
              'check.title.voice': 'Aufruf mit deinem PIN-code zu:',
              'code.error.country_not_supported': 'Dein Land wird nicht unterstützt. Versuche es bitte erneut.',
              'code.error.invalid_number': 'Deine Telefonnummer ist ungültig. Versuche es bitte erneut.',
              'code.error.invalid_number_length': 'Die Länge deiner Telefonnummer ist ungültig. Versuche es bitte erneut.',
              'code.error.max_attempts_reached': 'Du hast die maximalen Versuche erreicht.',
              'code.example': 'z.B. %example%',
              'code.service.sms.label': 'Text - (SMS -)',
              'code.service.sms.summary': 'Du erhältst einen Bestätigungscode per Kurzmitteilung (SMS)',
              'code.service.summary': 'Du erhältst deinen Bestätigungscode per:',
              'code.service.voice.label': 'Sprachanruf',
              'code.service.voice.summary': 'Du erhältst deinen Bestätigungscode per Sprachanruf',
              'code.submit.sms': 'Senden',
              'code.submit.voice': 'Anrufen',
              'code.summary': 'Wir senden dir den PIN-Code an die folgende Telefonnummer:',
              'code.title': 'Verifiziere bitte deine Telefonnummer.',
              'error.country_not_supported': 'Land nicht unterstützt',
              'error.direct_api_access_not_available': 'Direkte API-Zugriff nicht verfügbar',
              'error.duplicated_number': 'Diese Zahl wurde bereits überprüft. Bitte versuche es mit einer anderen.',
              'error.instant_validation_not_available': 'Sofortige Validierung nicht verfügbar',
              'error.invalid_app_key': 'Ungültige App Key',
              'error.invalid_number': 'Ungültige Telefonnummer',
              'error.invalid_number_length': 'Ungültige Telefonnummer-Länge',
              'error.invalid_pin_code': 'Ungültiger PIN-Code',
              'error.invalid_session': 'Ungültige Sitzung',
              'error.max_attempts_reached': 'Max-Versuche erreicht',
              'error.max_validations_reached': 'Max-Validierungen erreicht',
              'error.out_of_credits': 'Oups.. Wir konnten deine Telefonnummer leider nicht verifizieren.',
              'error.session_expired': 'Sitzung ist abgelaufen',
              'error.unknown_error': 'Unbekannter Fehler',
              'error.wait_to_retry': 'Bitte warten, um erneut zu versuchen',
              'help': 'Hilfe'
            }
          }
          $.getScript(
            "//cdn.ringcaptcha.com/widget/v2/bundle.min.js", ()=> @initialize()
          )
          @initialized = true

  initialize: ->
    widget = $('#phoneVerificationWidget')
    options = widget.data()
    options['events'] = {
      ready: (e) =>
        $(e.currentTarget).find('.phone-input').val(@verificationButton.data('phone'))
        $(e.currentTarget).find('.phone-input').prop('readonly',true).prop('disabled', true)
        $(e.currentTarget).off('click', '.country-button');
        $(e.currentTarget).find('[data-country-code="' + @verificationButton.data('countryCallingCode') + '"]').click()
      pending: ()=>
        $.ajax({
          type: 'POST',
          url: @verificationModal.data('pendingVerificationPath'),
          data: "ringcaptcha_session_id=" + @verificationModal.find('input[name="ringcaptcha_session_id"]').val()
        })
      verified: ()=>
        $.ajax({
          type: 'POST',
          url: @verificationModal.data('verificationPath'),
          data: "ringcaptcha_session_id=" + @verificationModal.find('input[name="ringcaptcha_session_id"]').val() +
            "&ringcaptcha_pin_code=" + @verificationModal.find('input[name="ringcaptcha_pin_code"]').val(),
          complete: ()=>
            window.location.reload()
        })
    }
    new RingCaptcha.Widget(widget, options).setup();
