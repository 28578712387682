(function() {

  const MEDIA_CANVAS_ELEMENT_CLASS = "media-element-canvas";
  const MARK_LETTER = "v";
  const DELETE_MARK_LETTER = "d";

  class ElementsContainer {
    constructor() {
      this.elements = new Map();
    }

    elementById(elementId) {
      return this.elements.get(elementId);
    }

    addElement(elementId, element) {
      this.elements.set(elementId, element);
    }

    get allElements() {
      return [...this.elements.values()];
    }

  }

  class ElementCursorPosition {
    constructor() {
      this.x = null;
      this.y = null;
      this.hoveredElementId = null;
    }
  }

  var elementsContainer = new ElementsContainer();
  var elementCursorPosition = new ElementCursorPosition();

  document.addEventListener('DOMContentLoaded', function() {
    var mediaElements = Array.from(document.getElementsByClassName(MEDIA_CANVAS_ELEMENT_CLASS));
    mediaElements.forEach(drawMediaWithDefaultShapes);

    document.addEventListener('mousemove', function(event){
      let classesOfHoveredElement = event.target.className;

      if (typeof classesOfHoveredElement !== 'string') {
        classesOfHoveredElement = '';
      }

      let currentElementId = event.target.id;
      if (!classesOfHoveredElement.includes(MEDIA_CANVAS_ELEMENT_CLASS)) {
        elementCursorPosition.x = null;
        elementCursorPosition.y = null;
        elementCursorPosition.hoveredElementId = null;
        var redraw = false;

        elementsContainer.allElements.forEach(element => {
          if (element.unhoverAllShapes()) {
            redraw = true;
          }
        });

        if (redraw) {
          elementsContainer.allElements.forEach(element => new Drawer(element).draw());
        }
      }
    });

    document.addEventListener('keypress', function(event) {
      if (elementCursorPosition.hoveredElementId) {
        let letter = String.fromCharCode(event.which || event.keyCode);
        let mediaCanvasElement = elementsContainer.elementById(elementCursorPosition.hoveredElementId);
        let x = elementCursorPosition.x
        let y = elementCursorPosition.y
        if (letter === MARK_LETTER) {
          new ShapeModificator(mediaCanvasElement, x, y).markShape();
        } else if (letter === DELETE_MARK_LETTER) {
          new ShapeModificator(mediaCanvasElement, x, y).unmarkShape();
        }
        new Drawer(mediaCanvasElement).draw();
        new DetectedFacesInputDataWriter(mediaCanvasElement).rewrite();
      }
    });
  });

  function drawMediaWithDefaultShapes(mediaElement) {
    var image = new Image();
    var mediaCanvasElement = new MediaCanvasElement(mediaElement, image);
    elementsContainer.addElement(mediaCanvasElement.mediaElement.id, mediaCanvasElement);

    image.onload = function() {
      try {
        mediaElement.width = mediaCanvasElement.width;
        mediaElement.height = mediaCanvasElement.height;
        new Drawer(mediaCanvasElement).draw();

        mediaElement.addEventListener('mousemove', function(event) {
          var rect = this.getBoundingClientRect();
          elementCursorPosition.x = event.clientX - rect.left;
          elementCursorPosition.y = event.clientY - rect.top;
          elementCursorPosition.hoveredElementId = mediaElement.id;

          new ShapeModificator(mediaCanvasElement, elementCursorPosition.x, elementCursorPosition.y).hoverShape();
          new Drawer(mediaCanvasElement).draw();
        });
      }
      catch(error) {
        console.log(error);
      }
    }
    image.src = mediaCanvasElement.uri;
    if (mediaCanvasElement.fallbackUri) {
      var fallbackUriData = JSON.parse(mediaCanvasElement.fallbackUri);
      image.onerror = function () {
        image.src = fallbackUriData[0];
        var fallbackUriSecondImage = fallbackUriData[1];
        image.onerror = function () {
          image.src = fallbackUriSecondImage;
          image.onerror = null;
        }
      }
    }
  }

  class MediaCanvasElement {

    constructor(mediaElement, image) {
      this.mediaElement = mediaElement;
      this.image = image;
      this.shapes = this.shapesCoordinates.map(rawData => new Shape(rawData['points'], this, {marked: rawData['marked']}));
    }

    get shapesCoordinates() {
      try {
        return JSON.parse(this.hiddenDetectedFacesInputDataFieldElement.value)["detected_faces_coordinates"];
      }
      catch(error) {
        console.log(error);
        return [];
      }
    }

    get uri() {
      return this.mediaElement.dataset.uri;
    }

    get fallbackUri() {
      return this.mediaElement.dataset.fallbackUri;
    }

    get width() {
      return this.mediaElement.dataset.currentWidth || this.originalWidth;
    }

    get height() {
      return this.originalHeight * (this.width / this.originalWidth);
    }

    get originalWidth() {
      return this.image.width;
    }

    get originalHeight() {
      return this.image.height;
    }

    shapeInCoordinates(x, y) {
      return this.shapes.find(shape => {
        return (x >= shape.initialPoint.adjustedX && x <= (shape.initialPoint.adjustedX + shape.width)) &&
               (y >= shape.initialPoint.adjustedY && y <= (shape.initialPoint.adjustedY + shape.height))
      });
    }

    unhoverAllShapes() {
      var modified = false
      this.shapes.forEach(shape => {
        if (shape.isHovered) modified = true;
        shape.hovered = false
      });
      return modified;
    }

    get hiddenDetectedFacesInputDataFieldElement() {
      return document.getElementById(this.mediaElement.id + "_faces");
    }

    get originalMediaUrl() {
      return this.mediaElement.dataset.originalUri;
    }

  }

  class Shape {
    constructor(rawPoints, mediaCanvasElement, options = {}) {
      this.rawPoints = rawPoints;
      this.points = rawPoints.map(rawPoint => new Point(rawPoint, new MediaSizeDifference(mediaCanvasElement)));
      this.hovered = false;
      this.marked = options['marked'] || false;
    }

    get isHovered() {
      return this.hovered;
    }

    get isMarked() {
      return this.marked;
    }

    get initialPoint() {
      return this.points[0];
    }

    get coordinatePointsExceptInitial() {
      return this.points.slice(1);
    }

    get width() {
      return this.points[1].adjustedX - this.initialPoint.adjustedX;
    }

    get height() {
      return this.points[this.points.length - 1].adjustedY - this.initialPoint.adjustedY;
    }

    isEqual(otherShape) {
      if (otherShape.toString() !== this.toString()) return false;
      if (otherShape.points.length != this.points.length) return false;
      for (var i = 0; i < this.points.length; i++) {
        if (!this.points[i].isEqual(otherShape.points[i])) return false;
      }
      return true;
    }
  }

  class ShapeModificator {
    constructor(mediaCanvasElement, x, y) {
      this.mediaCanvasElement = mediaCanvasElement;
      this.x = x;
      this.y = y
    }

    hoverShape() {
      if (this.selectedShape) {
        this.mediaCanvasElement.unhoverAllShapes();
        this.selectedShape.hovered = true;
      } else {
        this.mediaCanvasElement.unhoverAllShapes();
      }
    }

    markShape() {
      if (this.selectedShape) {
        this.selectedShape.marked = true;
      }
    }

    unmarkShape() {
      if (this.selectedShape) {
        this.selectedShape.marked = false;
      }
    }

    get selectedShape() {
      return this.mediaCanvasElement.shapeInCoordinates(this.x, this.y);
    }
  }

  class Point {

    constructor(rawPoints, mediaSizeDifference) {
      this.rawPoints = rawPoints;
      this.mediaSizeDifference = mediaSizeDifference
    }

    get originalX() {
      return this.rawPoints['x'];
    }

    get originalY() {
      return this.rawPoints['y'];
    }

    get adjustedX() {
      return this.originalX * this.mediaSizeDifference.widthDiff;
    }

    get adjustedY() {
      return this.originalY * this.mediaSizeDifference.heightDiff;
    }

    isEqual(otherPoints){
      if (otherPoints.toString() !== this.toString()) return false;
      return this.originalX == otherPoints.originalX && this.originalY == otherPoints.originalY;
    }
  }

  class MediaSizeDifference {

    constructor(mediaCanvasElement) {
      this.mediaCanvasElement = mediaCanvasElement
    }

    get widthDiff() {
      return this.mediaCanvasElement.width / this.mediaCanvasElement.originalWidth;
    }

    get heightDiff() {
      return this.mediaCanvasElement.height / this.mediaCanvasElement.originalHeight;
    }
  }

  class ShapeResolution {
    static get START_FONT_SIZE_IN_PX() { return 10; }
    static get DOWNSCALE_STEP() { return 100; }
    static get BOTTOM_POSITION() { return "bottom_position"; }
    static get TOP_POSITION() { return "top_position"; }

    constructor(points, mediaCanvasElement, ctx) {
      this.points = points;
      this.mediaCanvasElement = mediaCanvasElement;
      this.ctx = ctx;
    }

    get text() {
      return this.originalShapeWidth + "x" + this.originalShapeHeight;
    }

    get originalShapeWidth() {
      return Math.round(this.points[1].originalX - this.points[0].originalX);
    }

    get originalShapeHeight() {
      return Math.round(this.points[this.points.length - 1].originalY - this.points[0].originalY);
    }

    get currentShapeWidth() {
      return Math.round(this.points[1].adjustedX - this.points[0].adjustedX);
    }

    get currentShapeHeight() {
      return Math.round(this.points[this.points.length - 1].adjustedY - this.points[0].adjustedY);
    }

    get xAxisPosition() {
      var shapeLowerXPosition = this.points[this.points.length - 1].adjustedX;
      var marginForCentering = Math.round((this.currentShapeWidth - this.textWidth) / 2);
      return shapeLowerXPosition + marginForCentering;
    }

    get yAxisPosition() {
      if (this.leftSpaceForResolution(this.yAxisBottomPosition, ShapeResolution.BOTTOM_POSITION) >= this.requiredSpaceForResolution) {
        return this.yAxisBottomPosition;
      } else if (this.leftSpaceForResolution(this.yAxisTopPosition, ShapeResolution.TOP_POSITION) >= this.requiredSpaceForResolution) {
        return this.yAxisTopPosition;
      } else {
        return this.yAxisInnerBottomPosition;
      }
    }

    get yAxisBottomPosition() {
      let shapeLowerYPosition = this.points[this.points.length - 1].adjustedY;
      return shapeLowerYPosition + this.shapeResolutionMargin + this.fontSizeInPx;
    }

    get yAxisTopPosition() {
      let shapeHigherYPosition = this.points[0].adjustedY;
      return shapeHigherYPosition - (this.shapeResolutionMargin + this.fontSizeInPx);
    }

    get yAxisInnerBottomPosition() {
      let shapeLowerYPositionInShape = this.points[this.points.length - 1].adjustedY;
      return shapeLowerYPositionInShape - (this.shapeResolutionMargin + this.fontSizeInPx);
    }

    get requiredSpaceForResolution() {
      return this.shapeResolutionMargin + this.fontSizeInPx;
    }

    leftSpaceForResolution(yAxisPosition, positionType) {
      switch(positionType) {
        case ShapeResolution.BOTTOM_POSITION:
          return this.mediaCanvasElement.height - this.points[this.points.length - 1].adjustedY;
        case ShapeResolution.TOP_POSITION:
          return this.points[0].adjustedY;
        default:
          throw 'Invalid position type for ShapeResolution';
      }
    }

    get shapeResolutionMargin() {
      return Math.round(this.currentShapeHeight / ShapeResolution.DOWNSCALE_STEP);
    }

    get fontSizeInPx() {
      var gain = Math.round(this.mediaCanvasElement.width / ShapeResolution.DOWNSCALE_STEP);
      return ShapeResolution.START_FONT_SIZE_IN_PX + gain;
    }

    get textWidth() {
      return this.ctx.measureText(this.text).width;
    }

    get textHeight() {
      return this.fontSizeInPx;
    }
  }

  class ResolutionBackground {
    static get LEFT_RIGHT_PADDING() { return 5; }
    static get TOP_BOTTOM_PADDING() { return 8; }

    constructor(resolution) {
      this.resolution = resolution;
    }

    get xAxisPosition() {
      return this.resolution.xAxisPosition - ResolutionBackground.LEFT_RIGHT_PADDING;
    }

    get yAxisPosition() {
      return this.resolution.yAxisPosition - this.resolution.textHeight;
    }

    get width() {
      return this.resolution.textWidth + ResolutionBackground.LEFT_RIGHT_PADDING * 2;
    }

    get height() {
      return this.resolution.textHeight + ResolutionBackground.TOP_BOTTOM_PADDING;
    }

  }

  class Drawer {
    constructor(mediaCanvasElement) {
      this.mediaCanvasElement = mediaCanvasElement;
      this.ctx = mediaCanvasElement.mediaElement.getContext('2d');
    }

    draw() {
      this.clearCanvas();
      this.drawImage();
      this.mediaCanvasElement.shapes.forEach(shape => {
        if (shape.isMarked && !shape.isHovered) {
          this.drawMarkedShape(shape);
        } else if (shape.isMarked && shape.isHovered) {
          this.drawMarkedHoveredShape(shape);
        } else if (shape.isHovered) {
          this.drawHoveredShape(shape);
        } else {
          this.drawDefaultShape(shape);
        }
      });
    }

    drawMarkedShape(shape) {
      this.drawShape(shape, 3, "#F0DA0F");
    }

    drawMarkedHoveredShape(shape) {
      this.drawShape(shape, 3, "#F0DA0F");
      this.drawResolution(shape);
    }

    drawHoveredShape(shape) {
      this.drawShape(shape, 3, "#FF0000");
      this.drawResolution(shape);
    }

    drawDefaultShape(shape) {
      this.drawShape(shape, 1, "#FF0000");
    }

    drawResolution(shape) {
      var res = new ShapeResolution(shape.points, this.mediaCanvasElement, this.ctx);
      this.ctx.font = "bold " + res.fontSizeInPx + "px sans-serif";

      var resBackground = new ResolutionBackground(res);
      this.ctx.globalAlpha = 0.8;
      this.ctx.fillStyle = "#FFFFFF";
      this.ctx.fillRect(resBackground.xAxisPosition, resBackground.yAxisPosition, resBackground.width, resBackground.height);
      this.ctx.globalAlpha = 1.0;
      this.ctx.fillStyle = "#FF0000";
      this.ctx.fillText(res.text, res.xAxisPosition, res.yAxisPosition);
    }

    drawMarkedShape(shape) {
      this.drawShape(shape, 3, "#F0DA0F");
    }

    drawShape(shape, lineWidth, rectColor) {
      this.ctx.strokeStyle = rectColor;
      this.ctx.lineWidth = lineWidth;
      this.ctx.beginPath();
      this.ctx.moveTo(shape.initialPoint.adjustedX, shape.initialPoint.adjustedY);
      shape.coordinatePointsExceptInitial.forEach(coordinatePoint => this.ctx.lineTo(coordinatePoint.adjustedX, coordinatePoint.adjustedY));
      this.ctx.closePath();
      this.ctx.stroke();
    }

    clearCanvas() {
      this.ctx.clearRect(0, 0, this.mediaCanvasElement.width, this.mediaCanvasElement.height);
    }

    drawImage() {
      this.ctx.drawImage(this.mediaCanvasElement.image, 0, 0, this.mediaCanvasElement.width, this.mediaCanvasElement.height);
    }

  }

  class DetectedFacesInputDataWriter {
    constructor(mediaCanvasElement) {
      this.mediaCanvasElement = mediaCanvasElement;
    }

    rewrite() {
      var toJSON = Array.prototype.toJSON;
      delete Array.prototype.toJSON;
      this.mediaCanvasElement.hiddenDetectedFacesInputDataFieldElement.value = JSON.stringify(this.preparedDataToWrite);
      Array.prototype.toJSON = toJSON;
    }

    get preparedDataToWrite() {
      let data = {};
      data.media_url = this.mediaCanvasElement.originalMediaUrl;
      data.detected_faces_coordinates = this.mediaCanvasElement.shapes.map(shape => {
        var shapeData = {};
        shapeData.marked = shape.isMarked;
        shapeData.points = shape.points.map(point => {
          var pointData = {};
          pointData.x = point.originalX;
          pointData.y = point.originalY;
          return pointData;
        });
        return shapeData;
      });
      return data;
    }
  }

})();
