window.avatarUrl = null

$(document).on 'ready turbolinks:load', ->
  if $('#userAvatar a#user').length > 0
    window.profileAvatar = new ProfileAvatar($('#userAvatar a#user'))
    window.profileAvatar.loadAvatar()
  if $('#user_profile_picture').length > 0
    window.profileAvatar = new ProfileAvatar($('#user_profile_picture'))
    window.profileAvatar.loadAvatar()

class ProfileAvatar
  constructor: (root) ->
    @root = root

  loadAvatar: =>
    if !this.isAvatarCached()
      self = this
      $.ajax({
        url: @root.data('profileUrl'),
        dataType: "json",
        method: 'GET',
        xhrFields: {
          withCredentials: true
        },
        success: (data) =>
          localStorage.setItem('avatarUrl', data['avatar'])
          localStorage.setItem('avatarUrlExpiration:' + @root.data('profileUrl'), new Date().getTime() + (30 * 1000))
          self.appendAvatar()
      })
    else
      this.appendAvatar()

  reloadAvatar: =>
    localStorage.removeItem('avatarUrl')
    localStorage.removeItem('avatarUrlExpiration:' + @root.data('profileUrl'))
    this.loadAvatar()

  isAvatarCached: =>
    console.log('avatarUrlExpiration:' + @root.data('profileUrl'))
    if localStorage.getItem('avatarUrl') && localStorage.getItem('avatarUrlExpiration:' + @root.data('profileUrl'))
      if new Date().getTime() < localStorage.getItem('avatarUrlExpiration:' + @root.data('profileUrl'))
        return true
    return false

  appendAvatar: =>
    if !localStorage.getItem('avatarUrl').includes('assets/no_avatar')
      $('#userAvatar a#user').removeAttr('style')
      $('#userAvatar a#user').css('background-image', 'url(' + localStorage.getItem('avatarUrl') + ')')
      $('#userAvatar a#user').css('background-size', 'cover')
      $('#user_profile_picture').removeAttr('style')
      $('#user_profile_picture').css('background-image', 'url(' + localStorage.getItem('avatarUrl') + ')')
      $('#user_profile_picture').css('background-size', 'cover')
      $('#user_profile_picture').css('background-position', 'center')
    else
      if $('#userAvatar a#user').css('background-image')
        $('#userAvatar a#user').removeAttr('style')
